import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Table, Tag } from 'antd';
import { Bounce, toast } from 'react-toastify';
import useAuthRequest from '../../../services/requests.service';
import CreateFeaturedCompanyModal from './CreateFeaturedCompanyModal';
import {DeleteOutlined} from '@ant-design/icons'
const { confirm } = Modal;



const CustomHeader = (props) => {
  return (
    <th {...props} className="bg-blue-500 text-white p-2 text-left">
      {props.children}
    </th>
  );
};
const FeaturedCompany = ({setIsLoading}) => {
  const request = useAuthRequest();
  const [featuredPages, setFeaturedPages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    getFeaturedPages();
  }, []); // Empty dependency array ensures this runs once after the component mounts

  const getFeaturedPages = async () => {
    try {
      const res = await request.getDataAPI(`/featuredPages`);
      setFeaturedPages(res.featured);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false); // Ensure loading is set to false whether request succeeds or fails
    }
  };

  const handleDelete = (record) => {
    confirm({
      title: 'Êtes-vous sûr de vouloir supprimer cette page?',
      content: 'Cette action est irréversible',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      centered: true,
      async onOk() {
        setIsLoading(true)
        try{
        
            let res = await request.deleteDataAPI(`/featuredPage/${record._id}`)
            setIsLoading(false)

            // Filter out the deal that matches the record
            const updatedCateArray = featuredPages.filter((cate) => cate._id !== record._id);
            
            // Update the deals state with the new deals array and updated count
            setFeaturedPages([
              ...updatedCateArray
            ]);

            toast.success(res.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });

        }catch(e){
          setIsLoading(false)

          toast.error(e.msg?e.msg:"Une erreur est survenue", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }

      },
    });
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading message while fetching data
  }

  if (error) {
    return <div>Error: {error}</div>; // Show an error message if something goes wrong
  }


  const columns = [
    {
      title: 'Entreprise',
      dataIndex: 'page',
      key: 'page',
      render: (text) => <span>{text.name}</span>,
    },
  
    {
      title: 'Position',
      dataIndex: 'rank',
      key: 'rank',
      render: (text) => <span>{text}</span>,
  
    },
  
    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button danger onClick={()=>handleDelete(record)}><DeleteOutlined /></Button>
        </Space>
      ),
    },
  ];
  return (
    <div>

      <div className="sm:flex sm:justify-end sm:items-center mb-4">


        <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

          <Button size="large" type="primary" onClick={() => setIsModalOpen(true)}>
            <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
              <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
            </svg>
            <span className="hidden xs:block ml-2">Ajouter entreprise</span>
          </Button>
        </div>

      </div>
      <Table
        columns={columns}
        dataSource={featuredPages}
        className="bg-white dark:bg-slate-800 shadow rounded-lg"
        components={{
          header: {
            cell: CustomHeader,
          },
        }}
      />

    <CreateFeaturedCompanyModal   setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} setIsLoading={setIsLoading} setFeaturedPages={setFeaturedPages} featuredPages={featuredPages}/>
 
    </div>
  );
};

export default FeaturedCompany;
