import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/AuthProvider";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header/Header";

import useAuthRequest from "../services/requests.service"

import { useLocation, useNavigate, useParams } from "react-router-dom";
import CompanyBanner from "../components/Company/CompanyBanner";
import CompanySidebar from "../components/Company/CompanySidebar";
import Offers from "../components/Company/Offer/Offers";
import CreateOfferModal from "../components/Company/Offer/CreateOfferModal";
import Employees from "../components/Company/Employee/Employees";
import Settings from "../components/Company/Settings/Settings";
import { DotLoader } from "react-spinners";
import SettingsSub from "../components/Company/SettingsSub/SettingsSub";
const Company = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [page, setPage] = useState(null);

  const [queryPage, setQueryPage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDeal, setCurrentDeal] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [deals, setDeals] = useState(null);

  const navigate = useNavigate()
  const  {id} = useParams();
  const auth = useAuth();
  const request = useAuthRequest();
  const location = useLocation();




  useEffect(() => {

    getPageById();
  }, [id])

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get('page');
    setQueryPage(pageParam);
    console.log('change')
    
  }, [location.search]);


  const getPageById = async () => {
    console.log("page company")
    let res = await request.getDataAPI('/page/'+id)
    setPage(res.page)
  }




  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="h-full">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full  h-full   max-w-9xl mx-auto ">

            {/* Company banner */}
            <CompanyBanner {...page}/>


            {page && <div className="bg-white dark:bg-slate-800 shadow-lg rounded-2xl mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <CompanySidebar {...page} />

                {(queryPage ==='offers' || !queryPage) && <Offers setCurrentDeal={setCurrentDeal} deals={deals} setDeals={setDeals} page={page}  setIsModalOpen={setIsModalOpen}/>}
                {queryPage ==='employees' && <Employees page={page}  setIsModalOpen={setIsModalOpen}/>}
                {queryPage ==='settings' && <Settings page={page} setIsLoading={setIsLoading} setPage={setPage}  setIsModalOpen={setIsModalOpen}/>}
                {queryPage ==='settings-sub' && <SettingsSub  page={page} setIsLoading={setIsLoading} setPage={setPage}  setIsModalOpen={setIsModalOpen}/>}

          </div>
            </div>}


          </div>
        </main>

      </div>

      <CreateOfferModal isAdmin={false} setCurrentDeal={setCurrentDeal} currentDeal={currentDeal} deals={deals} setDeals={setDeals} page={page} setIsLoading={setIsLoading} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} setResponse={setResponse}/>

      {  isLoading &&     <div className=" top-0 left-0 absolute w-screen h-screen z-[10000]  flex justify-center items-center"> <DotLoader size={80} color="#6076E7" /></div>
        }    

    </div>
  );
};

export default Company;