import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';


const ReportDealModal = ({isModalOpen, setIsModalOpen, setIsLoading, currentDeal , setCurrentDeal}) => {
 
  const [form] = Form.useForm();
  

  const [formData, setFormData] = useState({
   
  });



  
  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData, form]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const afterClose = () => {
    form.resetFields(); // Reset the form fields
    setFormData({})
    setCurrentDeal(null)
  };


  return (
    <Modal
      centered
      bodyStyle={{ height: "80vh", maxHeight: "80vh", overflowY: "hidden" }}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      afterClose={afterClose}
      
    >

<div className='flex flex-col w-full h-full gap-14  '>
            <div className='flex flex-col'>
                <h2 className='font-semibold text-2xl text-center'>Signalement offre</h2>
            </div>

           {currentDeal && <div className=''>
                <h4 className='font-semibold'>Entreprise: <span className='font-normal'>{currentDeal.deal.company.name}</span></h4> 
                <h4 className='font-semibold'>Offre: <span className='font-normal'>{currentDeal.deal.offer}</span></h4> 
                <h4 className='font-semibold' >Signalé par: <span className='font-normal'>{currentDeal.user.fullname}</span></h4>
                <h4 className='font-semibold'>Message: <span className='font-normal'>{currentDeal.message}</span></h4> 

            </div>}

        </div>

    </Modal>
  );
};

export default ReportDealModal;
