import { Button, Form, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../hooks/AuthProvider';
import { useDatas } from '../../../hooks/DatasContext';

const CreateOffer = () => {

    const [form] = Form.useForm();
    const [step, setStep] = useState(1);
    const [selectedTags, setSelectedTags] = useState([])
    const [selectedTagsDescription, setSelectedTagsDescription] = useState([])
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        cf_password: '',
        agreement: false,
        firstname: '',
        lastname: '',
        mobile: '',
        companyName: '',
        titleJob: ''
    });
    const auth = useAuth();
    const {datas} = useDatas();

    useEffect(() => {
      console.log(datas)
    }, [datas])
    

    const filters = datas?.filters.map(filter => ({ value: filter.id, label: filter.name }));
 



    const handleSubmitEvent = (values) => {
        console.log(values)

     /*    if (values.email && values.password) {
            auth.loginAction(values);
            return;
        }
        alert("Please provide a valid input"); */
    };
  
    return (
    
    <Form
    layout="vertical"
    form={form}
    initialValues={formData}
    onFinish={handleSubmitEvent}
    style={{
        maxWidth: 600,
    }}
    className='w-full md:w-1/2 '
>
    {step === 1 && (
        <div>

            <Form.Item
                    label="Type(s) de promo"
                    name="tags"

                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                selectedTags.length > 0 ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir un type!'))
                        }
                    ]}
                >

                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un type"
                        options={filters}
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                        />
                </Form.Item>

                

                <Form.Item
                    label="Titre de l'offre"
                    name="offer"
                    rules={[
                        {
                            required: true,
                            message: 'Entrez votre titre!',
                        },
                        {
                            max: 140,
                            message: 'Le titre ne peut pas dépasser 140 caractères!',
                          }
                    ]}
                >
                    <Input size="large" maxLength={140} />
                </Form.Item>




                <Form.Item
                label="Localisation"
                name="adress"

            >
{/*                 <SearchAddress onAddressChange={handleAddressChange} address={localisationText} setAddress={setLocalisationText} />
 */}

               </Form.Item>



                <Form.Item
                    label="Description"
                    name="description"
                    hasFeedback
                    rules={[
                        {
                            max: 1000,
                            message: 'La description ne peut pas dépasser 1000 caractères!',
                          }
                    ]}

                >
                    <Input.TextArea size='large' maxLength={1000} showCount />
                </Form.Item>

                <Form.Item
                    label="Tag(s) de promo"
                    name="tagsDescription"



                    required={true}
                    rules={[
                        {
                            validator: (_, value) =>
                                selectedTagsDescription.length > 0 ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir un tag!'))
                        }
                    ]}
                >

                    <Select
                        mode="tags"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Choississez au moins un tag"
                        size="large"
                        dropdownStyle={{ zIndex: "100000" }}
                        />
                </Form.Item>

          
        </div>
    )}

    {step === 2 && (
        <div>
            <Form.Item
                label="Prénom"
                name="firstname"
                rules={[
                    {
                        required: true,
                        message: 'Entrez votre prénom!',
                    },
                    {
                        max: 100,
                        message: 'Le prénom ne peut pas dépasser 100 caractères!',
                    }
                ]}
            >
                <Input size="large" maxLength={100} />
            </Form.Item>

            <Form.Item
                label="Nom"
                name="lastname"
                rules={[
                    {
                        required: true,
                        message: 'Entrez votre nom!',
                    },
                    {
                        max: 100,
                        message: 'Le nom ne peut pas dépasser 100 caractères!',
                    }
                ]}
            >
                <Input size="large" maxLength={100} />
            </Form.Item>

            <Form.Item
                label="Téléphone"
                name="mobile"
                rules={[
                    {
                        required: true,
                        message: 'Entrez votre numéro de télephone!',
                    },
                ]}
            >
                <Input size="large" />
            </Form.Item>
        </div>
    )}

    {step === 3 && (
        <div>
            <Form.Item
                label="Entreprise"
                name="companyName"
            >
                <Input size="large" maxLength={150} placeholder='' />
            </Form.Item>

            <Form.Item
                label="Poste"
                name="titleJob"
            >
                <Input size="large" maxLength={100} placeholder='Ex: Chef Pâtissier chez ...' />
            </Form.Item>
        </div>
    )}

    <Form.Item className="item-next text-right">
{/*         {step > 1 && (
            <Button size="large" shape="round" onClick={prevStep} style={{ marginRight: '8px' }}>
                Précédent
            </Button>
        )} */}
        <Button size="large" shape="round" type="primary" htmlType="submit">
            {step < 3 ? 'Suivant' : 'Terminer'}
        </Button>
    </Form.Item>
</Form>
  )
}

export default CreateOffer