import React, { useEffect, useState } from 'react';
import useAuthRequest from '../services/requests.service';
import Logo from '../images/logos/logo-violet.png';
import AuthImage from '../images/auth-image.jpg';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Form, notification } from 'antd';

const Confirm = () => {
    const [status, setStatus] = useState(null);
    const [msg, setMsg] = useState(null);
    const [step, setStep] = useState(1);
    const [token, setToken] = useState("");
    const [url, setURL] = useState(null);
    const [email, setEmail] = useState("");

    const request = useAuthRequest();
    const navigate = useNavigate();

    useEffect(() => {
        if (process.env.REACT_APP_URL) {
            setURL(process.env.REACT_APP_URL);
        }
    }, []);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenFromURL = urlParams.get('token');
        setToken(tokenFromURL);

        if (tokenFromURL) {
            confirmToken(tokenFromURL);
        }
    }, [url]);

    const confirmToken = async (token) => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/confirmToken/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: null, // Pass the email here
            });
            setStatus(200);

        } catch (e) {
            console.error(e);
            setStatus(400);
            setMsg("Lien de confirmation invalide!");
        }
    };
    const handleSend = async () => {
        try {
            const res = await fetch(`${process.env.REACT_APP_BASE_URL}/api/sendConfirmationLink`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }), // Pass the email here
            });
            if (res.status === 200) {
                notification.success({
                    message: 'Lien envoyé',
                    description: 'Un nouveau lien de confirmation a été envoyé à votre adresse email.',
                });
                setStep(1);
            } else {
                notification.error({
                    message: 'Erreur',
                    description: "Une erreur s'est produite lors de l'envoi du lien.",
                });
            }
        } catch (e) {
            notification.error({
                message: 'Erreur',
                description: "Une erreur s'est produite lors de l'envoi du lien.",
            });
        }
    };
    

    return (
        <main className="bg-white dark:bg-slate-900">
            <div className="relative md:flex">
                {/* Content */}
                <div className="md:w-1/2">
                    <div className="min-h-[100dvh] h-full flex flex-col justify-center items-center">
                        <img
                            className="absolute top-1 left-1 cursor-pointer"
                            src={Logo}
                            width={200}
                            alt="logo"
                            onClick={() => navigate('/')}
                        />

                        {
                            status===200 &&        <div className="flex flex-col items-center max-w-sm mx-auto w-full px-4 py-8 gap-6">
                            <h1 className="text-xl text-slate-800 dark:text-slate-100 font-bold text-center">
                                Compte activé!
                            </h1>
                            <span>Vous pouvez quitter cette page</span>
                            <Button onClick={()=>navigate('/')}>Se Connecter</Button>

{/*                             {step === 1 && (
                                <Button size="large" onClick={() => setStep(2)}>
                                    Renvoyer un lien
                                </Button>
                            )}

                            {step === 2 && (
                                <div className="flex flex-col gap-3 w-full">
                                    <h2>Renvoyer lien de confirmation</h2>
                                    <Form layout="vertical" onFinish={handleSend}>
                                        <Form.Item
                                            label="Entrez votre email"
                                            name="email"
                                            rules={[
                                                { required: true, message: 'Veuillez entrer votre email!' },
                                                { type: 'email', message: 'Veuillez entrer un email valide!' },
                                            ]}
                                        >
                                            <Input
                                                maxLength={140}
                                                size="large"
                                                placeholder="Entrez votre email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Item>
                                        <Form.Item className='flex justify-end'>
                                            <Button type="primary" size="large"  htmlType="submit">
                                                Envoyer
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </div>
                            )} */}
                        </div>
                        }

                        {status === 400 && (
                            <div className="flex flex-col items-center max-w-sm mx-auto w-full px-4 py-8">
                                <h1 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-6 text-center">
                                    {msg}
                                </h1>

                                {step === 1 && (
                                    <Button size="large" onClick={() => setStep(2)}>
                                        Renvoyer un lien
                                    </Button>
                                )}

                                {step === 2 && (
                                    <div className="flex flex-col gap-3 w-full">
                                        <h2>Renvoyer lien de confirmation</h2>
                                        <Form layout="vertical" onFinish={handleSend}>
                                            <Form.Item
                                                label="Entrez votre email"
                                                name="email"
                                                rules={[
                                                    { required: true, message: 'Veuillez entrer votre email!' },
                                                    { type: 'email', message: 'Veuillez entrer un email valide!' },
                                                ]}
                                            >
                                                <Input
                                                    maxLength={140}
                                                    size="large"
                                                    placeholder="Entrez votre email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </Form.Item>
                                            <Form.Item className='flex justify-end'>
                                                <Button type="primary" size="large"  htmlType="submit">
                                                    Envoyer
                                                </Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                {/* Image */}
                <div
                    className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2"
                    aria-hidden="true"
                >
                    <img
                        className="object-cover object-center w-full h-full"
                        src={AuthImage}
                        width="760"
                        height="1024"
                        alt="Authentication"
                    />
                </div>
            </div>
        </main>
    );
};

export default Confirm;
