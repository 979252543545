import React from 'react'
import { Link } from 'react-router-dom'

const PageCard = (props) => {
    return (
        <Link to={`/company/${props._id}?page=offers`} className=" aspect-square p-8  col-span-full sm:col-span-6 xl:col-span-3 bg-white hover:bg-royalblue-50  dark:hover:bg-slate-900 dark:bg-slate-800 shadow-lg rounded-2xl border border-slate-200 dark:border-slate-700">
            <div className="flex flex-col items-center justify-center h-full ">
                {/* Card top */}
                <div className="w-full h-full flex flex-col justify-center items-center">
                        <div className="flex justify-center mb-2 border rounded-full w-[125px]  h-[125px] ">
                                <img className="rounded-full" src={props.avatar} width="125" height="125" alt={props.name} />
                        </div>
                        <div className="text-center">
                            <Link className="inline-flex text-slate-800 dark:text-slate-100 hover:text-slate-900 dark:hover:text-white" to={props.link}>
                                <h2 className="text-xl leading-snug justify-center font-semibold">{props.name}</h2>
                            </Link>
                        </div>
                        <div className="flex justify-center items-center">
                            { (props.typePage ==="company" && props.typeCompany !== 'multi') && <span  className="text-sm  text-slate-600 dark:text-slate-100 -mt-0.5 mr-1">(Entreprise)</span>}
                            { props.typeCompany ==="multi" && <span  className="text-sm  text-slate-600 dark:text-slate-100 -mt-0.5 mr-1">(Groupe)</span>}
                            { (props.typePage ==="partner") && <span  className="text-sm  text-slate-600 dark:text-slate-100  -mt-0.5 mr-1">(Partenaire)</span>}
                        </div>
                       
                   
                </div>

            </div>
        </Link>
    )
}

export default PageCard