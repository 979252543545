
export const isInArray = (array, id, role) => {
    if(!array) return;
    return array.some(element => 
      (element.user._id?element.user._id.toString():element.user) === id.toString() && 
      (role === undefined || element.role === role)
    );
  };

  export const getParamsFilter =  (filterData) => {
    let param = ""

    if(!filterData) return "";

    param = Object.entries(filterData)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
   



    return param
    
}
  
export const validateURL = (_, value) => {
  const urlPattern = new RegExp('^(https?:\\/\\/)' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?' + // port
    '(\\/[-a-z\\d%_.~+]*)*' + // path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d%_.~+=]*)?$', 'i'); // fragment locator
  if (!value || urlPattern.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(new Error('Entrez une URL valide!'));
};

