import React from "react";
import { Outlet } from "react-router-dom";
import useAuthRedirect from "../hooks/RedirectProvider";

const PublicRoute = () => {
  useAuthRedirect();

  return <Outlet />;
};

export default PublicRoute;
