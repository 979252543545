import React, { useEffect, useState } from "react";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header/Header";
import { DotLoader } from "react-spinners";
import WelcomeBanner from "../components/Admin/WelcomeBanner";
import AdminSidebar from "../components/Admin/AdminSidebar";
import Offers from "../components/Admin/Offers/Offers";
import FeedManagement from "../components/Admin/Feed/FeedManagement";
import { useLocation } from "react-router-dom";
const DashboardAdmin = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [queryPage, setQueryPage] = useState('');
  const location = useLocation();



  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get('page');
    setQueryPage(pageParam);
    console.log('change')
    
  }, [location.search]);


  return (
    <div className="flex h-[100dvh] overflow-hidden">

      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">

        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="grow">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full  max-w-9xl mx-auto ">

            {/* Welcome banner */}
            <WelcomeBanner />

            <div className="bg-white dark:bg-slate-800 shadow-lg rounded-2xl mb-8">
              <div className="flex flex-col md:flex-row md:-mr-px">
                <AdminSidebar/>

                 {(queryPage ==='offers' || !queryPage) && <Offers setIsLoading={setIsLoading}/>}
                 {(queryPage ==='feed') && <FeedManagement setIsLoading={setIsLoading}/>}

          </div>
            </div>


          </div>
        </main>

      </div>


        {  isLoading &&       <div className=" absolute w-screen h-screen z-60  flex justify-center items-center"> <DotLoader size={80} color="#6076E7" /></div>
        }    

 


</div>
  );
};

export default DashboardAdmin;