import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bounce, toast } from 'react-toastify';
import { useDatas } from './DatasContext';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    const storedUser = localStorage.getItem('currentUser');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
    setLoading(false);
  }, []);

  const login = async (credentials) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials)
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data);
        localStorage.setItem('currentUser', JSON.stringify(data));
        navigate('/dashboard'); // Navigate to a protected route upon successful login
      } else {
        console.log(data)
        toast.error(data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
          });
        //throw new Error(data.msg || 'Login failed');
      }
    } catch (error) {
      toast.error('Une erreur est survenue!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });

      console.error('Login error:', error);
      //throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('currentUser');
    navigate('/login');
  };

  const updateUser = (updatedUserData) => {
    // Retrieve the current user from localStorage
    let storedUser = localStorage.getItem('currentUser');
  
    if (storedUser) {
      // Parse the stored user data into an object
      storedUser = JSON.parse(storedUser);
  
      // Update the user object with the new data
      storedUser.user = { ...storedUser.user, ...updatedUserData };
  
      // Store the updated user back into localStorage
      localStorage.setItem('currentUser', JSON.stringify(storedUser));
  
      // Update the state with the new user data
      setUser(storedUser);
    }
  
    console.log(updatedUserData);
  };
  

  const refreshToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/refresh_token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      });
      const data = await response.json();
      if (response.ok) {
        setUser(data);
        localStorage.setItem('currentUser', JSON.stringify(data));
        return data.access_token;
      } else {
        logout();
        throw new Error(data.msg || 'Failed to refresh token');
      }
    } catch (error) {
      console.error('Refresh token error:', error);
      logout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, refreshToken, updateUser, loading, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
