import { useAuth } from "../hooks/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../images/logos/logo-violet.png';
import AuthImage from '../images/auth-image.jpg';
import { Button, Checkbox, Form, Input } from "antd";
import { useState } from "react";
import useAuthRequest from "../services/requests.service";
import { Bounce, toast } from "react-toastify";

const Signup = () => {
    const [form] = Form.useForm();
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
        cf_password: '',
        agreement: false,
        firstname: '',
        lastname: '',
        mobile: '',
        companyName: '',
        titleJob: ''
    });

    const navigate = useNavigate();
    const auth = useAuth();
    const request = useAuthRequest();

    const handleSubmitEvent = async (values) => {
        const newFormData = { ...formData, ...values };
        newFormData.fullname = newFormData.firstname + ' ' + newFormData.lastname
        setFormData(newFormData);

        if (step < 3) {
            setStep(step + 1);
        } else if (step === 3) {
            // Final form submission
            console.log(newFormData);

            try{
                let res = await request.postDataAPIWithoutToken('/register', newFormData)
                toast.success(res.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false, 
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
                navigate('/')

            }catch(e){
                toast.error(e.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
            }
        }
    };

    const prevStep = () => {
        console.log("prev")
        setStep((prevStep) => prevStep - 1);
    };

    return (
        <main className="bg-white dark:bg-slate-900">
            <div className="relative md:flex">
                <div className="md:w-1/2">
                    <div className="min-h-[100dvh] h-full flex flex-col justify-center items-center">
                        <img className="absolute top-1 left-1" src={Logo} width={200} alt="logo" onClick={()=>navigate('/')} />
                        <div className="max-w-sm mx-auto w-full px-4 py-8">
                            <div className="px-4 pt-12 pb-8">
                                <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6 text-center">Créer un compte</h1>
                                <div className="max-w-md mx-auto w-full mt-8">
                                    <div className="relative">
                                        <div className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200 dark:bg-slate-700" aria-hidden="true"></div>
                                        <ul className="relative flex justify-between w-full">
                                            <li>
                                                <span className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step >= 1 ? 'bg-royalblue-500 text-white' : 'bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400'}`}>1</span>
                                            </li>
                                            <li>
                                                <span className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step >= 2 ? 'bg-royalblue-500 text-white' : 'bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400'}`}>2</span>
                                            </li>
                                            <li>
                                                <span className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${step >= 3 ? 'bg-royalblue-500 text-white' : 'bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400'}`}>3</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <Form
                                layout="vertical"
                                form={form}
                                initialValues={formData}
                                onFinish={handleSubmitEvent}
                                style={{
                                    maxWidth: 600,
                                }}
                            >
                                {step === 1 && (
                                    <div>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            rules={[
                                                {
                                                    type: "email",
                                                    required: true,
                                                },
                                                {
                                                    max: 140,
                                                    message: 'L\'email ne peut pas dépasser 140 caractères!',
                                                }
                                            ]}
                                        >
                                            <Input size="large" maxLength={140} />
                                        </Form.Item>

                                        <Form.Item
                                            label="Mot de passe"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez votre mot de passe!',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password size='large' />
                                        </Form.Item>

                                        <Form.Item
                                            label="Mot de passe de confirmation"
                                            name="cf_password"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez le mot de passe de confirmation!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Les mots de passe ne correspondent pas!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password size="large" />
                                        </Form.Item>

                                        <Form.Item
                                            name="agreement"
                                            valuePropName="checked"
                                            rules={[
                                                {
                                                    validator: (_, value) =>
                                                        value ? Promise.resolve() : Promise.reject(new Error('Acceptez les CGU pour continuer !')),
                                                },
                                            ]}
                                        >
                                            <Checkbox>
                                                J'accepte les{' '}
                                                <span style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={()=>navigate('/privacyPolicy')}>
                                                    CGU de Behandy
                                                </span>
                                            </Checkbox>
                                        </Form.Item>
                                    </div>
                                )}

                                {step === 2 && (
                                    <div>
                                        <Form.Item
                                            label="Prénom"
                                            name="firstname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez votre prénom!',
                                                },
                                                {
                                                    max: 100,
                                                    message: 'Le prénom ne peut pas dépasser 100 caractères!',
                                                }
                                            ]}
                                        >
                                            <Input size="large" maxLength={100} />
                                        </Form.Item>

                                        <Form.Item
                                            label="Nom"
                                            name="lastname"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez votre nom!',
                                                },
                                                {
                                                    max: 100,
                                                    message: 'Le nom ne peut pas dépasser 100 caractères!',
                                                }
                                            ]}
                                        >
                                            <Input size="large" maxLength={100} />
                                        </Form.Item>

                                        <Form.Item
                                            label="Téléphone"
                                            name="mobile"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez votre numéro de télephone!',
                                                },
                                            ]}
                                        >
                                            <Input size="large" />
                                        </Form.Item>
                                    </div>
                                )}

                                {step === 3 && (
                                    <div>
                                        <Form.Item
                                            label="Entreprise"
                                            name="companyName"
                                        >
                                            <Input size="large" maxLength={150} placeholder='' />
                                        </Form.Item>

                                        <Form.Item
                                            label="Poste"
                                            name="titleJob"
                                        >
                                            <Input size="large" maxLength={100} placeholder='Ex: Chef Pâtissier chez ...' />
                                        </Form.Item>
                                    </div>
                                )}

                                <Form.Item className="item-next text-right">
                                    {step > 1 && (
                                        <Button size="large" shape="round" onClick={prevStep} style={{ marginRight: '8px' }}>
                                            Précèdent
                                        </Button>
                                    )}
                                    <Button size="large" shape="round" type="primary" htmlType="submit">
                                        {step < 3 ? 'Suivant' : 'Terminer'}
                                    </Button>
                                </Form.Item>
                            </Form>

                            <div className="pt-5 mt-6 border-t border-slate-200 dark:border-slate-700">
                                <div className="text-sm">
                                    Déjà un compte? <Link className="font-medium text-royalblue-500 hover:text-royalblue-600 dark:hover:text-royalblue-400" to="/login">Se connecter</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
                </div>
            </div>
        </main>
    );
};

export default Signup;
