import React, { useEffect, useState } from 'react'
import useAuthRequest from '../../../services/requests.service'
import InvoiceTable from './InvoicesTable';
import { DotLoader } from 'react-spinners';

const options = {
    day: 'numeric', // Two-digit day (e.g., 28)
    month: 'short', // Short month name (e.g., "janv." for January)
    year: 'numeric', // Four-digit year (e.g., 2024)
    timeZone: 'UTC', // Set the time zone to UTC to match the Unix timestamp
};

const InformationView = ({ page, subscriptions, isLoading, invoice, stripeProducts }) => {

    const request = useAuthRequest();
    const [startDate, setStartDate] = useState('');
    const [nextDate, setNextDate] = useState('');


    useEffect(() => {

        if (subscriptions[0]) {
            const date = new Date(subscriptions[0].start_date * 1000); // Multiply by 1000 to convert from seconds to milliseconds
            const formattedDate = date.toLocaleDateString('fr-FR', options);
            setStartDate(formattedDate);

        }

        if (invoice) {
            const date = new Date(invoice.next_payment_attempt * 1000); // Multiply by 1000 to convert from seconds to milliseconds
            const formattedDate = date.toLocaleDateString('fr-FR', options);
            setNextDate(formattedDate);
        }

        return () => {

        }
    }, [subscriptions])

    const generateCheckoutLink = async (product) => {
        const content = { company: page._id, email: page.email, name: page.name, customerId: page.customerId, priceId: product.priceId, qty: 1 }
        let resLink = await request.postDataAPI('stripe/checkout-link', content);
        if (resLink.session.url) {
            window.location.href = resLink.session.url;
        }
    }

    const generatePortailLink = async () => {
        const content = { customerId: page.customerId, url: `${window.location.href}` }
        let resLink = await request.postDataAPI('stripe/portal-link', content);
        if (resLink.session.url) {
            window.location.href = resLink.session.url;
        }
    }


    return (
        <>
        {isLoading ? <div className="  min-h-[200px]  flex justify-center items-center"> <DotLoader size={60} color="#6076E7" /></div> :<div>
            {(stripeProducts?.length > 0 && subscriptions.length === 0) && <div className="mb-8">
                <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-4">Plan</h2>
            </div>
            }

            {(stripeProducts?.length > 0 && subscriptions.length === 0) && <div className="grid grid-cols-12 gap-6">

                {
                    stripeProducts.map(prod => (
                        <div className="relative col-span-full xl:col-span-4 bg-white dark:bg-slate-800 shadow-md rounded-sm border border-slate-200 dark:border-slate-700">
                            <div className="absolute top-0 left-0 right-0 h-0.5 bg-emerald-500" aria-hidden="true"></div>
                            <div className="px-5 pt-5 pb-6 border-b border-slate-200 dark:border-slate-700">
                                <header className="flex items-center mb-2">
                                    <div className="w-6 h-6 rounded-full shrink-0 bg-gradient-to-tr from-emerald-500 to-emerald-300 mr-3">
                                        <svg className="w-6 h-6 fill-current text-white" viewBox="0 0 24 24">
                                            <path d="M12 17a.833.833 0 01-.833-.833 3.333 3.333 0 00-3.334-3.334.833.833 0 110-1.666 3.333 3.333 0 003.334-3.334.833.833 0 111.666 0 3.333 3.333 0 003.334 3.334.833.833 0 110 1.666 3.333 3.333 0 00-3.334 3.334c0 .46-.373.833-.833.833z" />
                                        </svg>
                                    </div>
                                    <h3 className="text-lg text-slate-800 dark:text-slate-100 font-semibold">{prod.name}</h3>
                                </header>
                                <div className="text-sm mb-2">Idéal pour les entreprises qui ont besoin d'une solution personnalisée.</div>
                                {/* Price */}
                                <div className="text-slate-800 dark:text-slate-100 font-bold mb-4">
                                    <span className="text-sm text-slate-500 font-medium mr-5">A partir de </span><span className="text-3xl">{prod.price_per_month}€</span><span className="text-slate-500 font-medium text-sm">/mois</span>
                                </div>
                                {/* CTA */}
                                <button onClick={() => generateCheckoutLink(prod)} className="btn border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 text-slate-600 dark:text-slate-300 w-full">S'abonner</button>
                            </div>
                            <div className="px-5 pt-4 pb-5">
                                <div className="text-xs text-slate-800 dark:text-slate-100 font-semibold uppercase mb-4">Qu'est-ce qui est inclus?</div>
                                {/* List */}
                                <ul>
                                    <li className="flex items-center py-1">
                                        <svg className="w-3 h-3 shrink-0 fill-current text-emerald-500 mr-2" viewBox="0 0 12 12">
                                            <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                                        </svg>
                                        <div className="text-sm">Des milliers d'offres de réductions</div>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    ))
                }


            </div>}


            {
                subscriptions.length > 0 && <div className='flex flex-col'>




                    <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-8">Détails abonnement</h2>

                    <div className='relative flex flex-col border-[1px] rounded-md p-4  pt-16'>
                        {startDate && <div className='absolute top-2 left-0 bg-royalblue-500 text-white text-sm font-medium px-3 py-2 rounded-r-lg'>
                            Membre depuis le {startDate}
                        </div>}
                        <div className='flex flex-col'>
                            <h3 className='font-bold text-black'>{subscriptions[0].items.data[0].productName}</h3>
                            {nextDate && <span className='font-normal text-slate-500'>Prochain paiement: {nextDate}</span>
                            }                               
{  nextDate &&                           <span className='font-normal text-slate-500'>Tarif: <span className='ml-1 font-semibold'>{invoice?.amount_due ? invoice?.amount_due / 100 : 0}€/mois</span>
                         </span> }
                            <span className='font-normal text-slate-500'>Quantité: <span className='ml-1 font-semibold'>{subscriptions[0].quantity} </span> </span>

                        </div>

                        <div className='flex justify-between items-center hover:cursor-pointer hover:bg-slate-50 px-2 py-3' onClick={generatePortailLink}>

                            <h3 className='font-bold text-black'>Gérer abonnement</h3>

                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="black" fill-rule="evenodd" d="m7.053 2.158l7.243 7.256a.66.66 0 0 1 .204.483a.705.705 0 0 1-.204.497c-2.62 2.556-5.145 5.023-7.575 7.401c-.125.117-.625.408-1.011-.024c-.386-.433-.152-.81 0-.966l7.068-6.908l-6.747-6.759c-.246-.339-.226-.652.06-.939c.286-.287.607-.3.962-.04Z" /></svg>

                        </div>

                    </div>
                </div>
            }

          {/*   {
                invoices.length > 0  && <>
                    <InvoiceTable invoices={invoices}/>
                </>
            } */}
        </div>}

        </>
    )
}

export default InformationView