import { Button, Space, Table, Tag, Modal } from 'antd';
import React from 'react';
import { Bounce, toast } from 'react-toastify';
import moment from 'moment';
import useAuthRequest from '../../../../services/requests.service';

const { confirm } = Modal;

const OffersTable = ({ datas, setDeals, setIsModalOpen, setCurrentDeal,  currentPage, pageSize, total, onPageChange, setPage }) => {
  const request =useAuthRequest()
  const CustomHeader = (props) => {
    return (
      <th {...props} className="bg-blue-500 text-white p-2 text-left">
        {props.children}
      </th>
    );
  };

  const handleUpdate = (record) => {
      setPage(record.company)
      setIsModalOpen(true);
      setCurrentDeal(record);
  }

  const handleDelete = (record) => {
    confirm({
      title: 'Êtes-vous sûr de vouloir supprimer cette offre?',
      content: 'Cette action est irréversible',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      centered: true,
      onOk() {

        try{
        
           let res = request.deleteDataAPI(`/deal/${record._id}`)
            // Filter out the deal that matches the record
            const updatedDealsArray = datas.deals.filter((deal) => deal._id !== record._id);
            
            // Update the deals state with the new deals array and updated count
            setDeals({
              ...datas,
              deals: updatedDealsArray,
              count: updatedDealsArray.length,
            });

            toast.success("Offre supprimée!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });

        }catch(e){

        }

      },
    });
  };

  const columns = [
    {
      title: 'Entreprise',
      dataIndex: 'company',
      key: 'company',
      render: (text) => <span>{text.name}</span>,
    },
    {
      title: 'Offres',
      dataIndex: 'offer',
      key: 'offer',
      render: (text) => <span>{text}</span>,
    },

    {
      title: 'Tags',
      key: 'tagsDescription',
      dataIndex: 'tagsDescription',
      render: (_, { tagsDescription }) => (
        <>
          {tagsDescription &&
            tagsDescription.length > 0 &&
            tagsDescription.map((tag) => {
              let color = tag.length > 5 ? 'geekblue' : 'green';
              if (tag === 'loser') {
                color = 'volcano';
              }
              return (
                <Tag color={color} key={tag}>
                  {tag.toUpperCase()}
                </Tag>
              );
            })}
        </>
      ),
    },
    {
      title: 'Date Publication',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'hide-on-mobile', // Apply the responsive class here
      render: (createdAt) => (
        <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
      ),
    },

    {
      title: 'Actions',
      key: 'action',
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => handleUpdate(record)}>Modifier</Button>
          <Button danger onClick={() => handleDelete(record)}>Supprimer</Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={datas.deals}
      className="bg-white dark:bg-slate-800 shadow rounded-lg"
      components={{
        header: {
          cell: CustomHeader,
        },
      }}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: total,
        onChange: onPageChange,
      }}
    />
  );
};

export default OffersTable;
