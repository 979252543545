import { Button, Form, Input, Upload } from 'antd';
import React, { useState } from 'react';
import SearchAddress from '../../../Utils/SearchAddress';
import { PlusOutlined } from '@ant-design/icons';

const FormManagementCompanyPart1 = ({ current, setCurrent, formData, setFormData, page }) => {
  const [form] = Form.useForm();



  // Pre-fill form with data from formData
  form.setFieldsValue(formData);

  const onFinish = (values) => {
    setFormData({ ...formData, ...values }); // Update the main formData state
    setCurrent(current + 1); // Move to the next step 
    console.log(formData)
  };

  const handleAddressChange = (fieldName, place) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: place
    }));
    form.setFieldsValue({
      [fieldName]: place
    });
  };




  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ width: '100%' }}
    >


      <Form.Item
        label={page.typeCompany === 'inde' ? 'Dénomination sociale de l’entreprise' : 'Dénomination sociale de la maison mère*'}
        name="registeredName"
        rules={[
          {
            required: true,
            message: 'Entrez votre dénomination sociale!',
          },
          {
            max: 120,
            message: 'La dénomination sociale ne peut pas dépasser 120 caractères!',
          },
        ]}
      >
        <Input size="large" maxLength={120} />
      </Form.Item>

      <Form.Item
        label="N° SIRET"
        name="siret"
        rules={[
          {
            required: true,
            message: 'Entrez votre SIRET!',
          },
          {
            validator: (_, value) =>
              value && /^\d{14}$/.test(value)
                ? Promise.resolve()
                : Promise.reject(new Error('SIRET doit contenir exactement 14 chiffres!')),
          },
        ]}
      >
        <Input size="large" />
      </Form.Item>

      <Form.Item label="Adresse postale" name="postalAddress">
              <SearchAddress onAddressChange={handleAddressChange} address={formData.postalAddress ? formData.postalAddress : null} fieldName="postalAddress" />
            </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '35px' }}>
        <Button size="large" shape="round" type="primary" htmlType="submit">
          Suivant
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormManagementCompanyPart1;
