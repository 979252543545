import { Button, Form, Modal, Steps, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import FormManagementCompanyPart1 from './Form/FormManagementCompanyPart1';
import FormManagementCompanyPart2 from './Form/FormManagementCompanyPart2';
import FormManagementCompanyPart3 from './Form/FormManagementCompanyPart3';
import { Bounce, toast } from 'react-toastify';
import useAuthRequest from '../../../services/requests.service';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const Infos = ({ page }) => {
    const [form] = Form.useForm();
    const { postDataAPIWithoutToken, patchDataAPI } = useAuthRequest();
    const [current, setCurrent] = useState(0);
    const [formData, setFormData] = useState({ ...page }); // Initialize with page data
    const [imgList, setImgList] = useState(page.avatar ? [{ url: page.avatar }] : []); // Initialize with page's avatar if available
    const [selectedImage, setSelectedImage] = useState(null); // Store selected image for upload on modify
    const request = useAuthRequest();
    const navigate = useNavigate();

    useEffect(() => {
        setFormData({ ...page });
    }, [page]);

    const onChange = (value) => {
        if (value < current) {
            setCurrent(value);
        }
    };

    const steps = [
        { title: 'Informations 1/3' },
        { title: 'Informations 2/3' },
        { title: 'Informations 3/3' },
    ];

    const handleDeleteAccount = () => {
        confirm({
            title: 'Êtes-vous sûr de vouloir supprimer cette page?',
            content: 'Cette action est irréversible',
            okText: 'Oui',
            okType: 'danger',
            cancelText: 'Non',
            centered: true,
            async onOk() {
                if (page.subscription) {
                    toast.error("Vous avez un abonnement en cours, veuillez l'annuler avant de supprimer cette page!", {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'light',
                        transition: Bounce,
                    });
                    return;
                }

                try {
                    const res = await request.deleteDataAPI(`pagePromo/${page._id}`);
                    toast.success(res.msg, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'light',
                        transition: Bounce,
                    });
                    navigate('/dashboard');
                } catch (e) {
                    toast.error(e.msg, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'light',
                        transition: Bounce,
                    });
                }
            },
        });
    };

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLessThan3MB = file.size / 1024 / 1024 < 3;

        if (!isJpgOrPng) {
            form.setFields([{ name: 'avatar', errors: ['Le fichier doit être au format JPG ou PNG.'] }]);
            return Upload.LIST_IGNORE;
        }

        if (!isLessThan3MB) {
            form.setFields([{ name: 'avatar', errors: ['La taille du fichier ne doit pas dépasser 3MB.'] }]);
            return Upload.LIST_IGNORE;
        }

        return true;
    };

    const onFileChange = ({ fileList }) => {
        setImgList(fileList);
        if (fileList.length > 0) {
            setSelectedImage(fileList[0].originFileObj); // Store selected image file for later upload
        } else {
            setSelectedImage(null);
        }
    };

    // Dummy customRequest to prevent auto-upload behavior
    const customRequest = ({ onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    // Image upload will happen only when "Modifier" is clicked
    const imageUpload = async () => {
        if (!selectedImage) {
            return null;
        }

        const formDataS3 = new FormData();
        formDataS3.append('image', selectedImage);

        try {
            const resS3 = await postDataAPIWithoutToken('uploadImage', formDataS3);
            return resS3.uploadLocation;
        } catch (error) {
            console.error('Image upload failed:', error);
            toast.error("Erreur lors de l'upload de l'image", {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: 'light',
            });
            return null;
        }
    };

    const handleUpdateLogo = async () => {
        const imageUrl = await imageUpload(); // Upload image if available

        if (imageUrl) {
            try {
                const res = await patchDataAPI(`/page/${page._id}`, { avatar: imageUrl });
                toast.success('Logo modifié avec succès', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                });
            } catch (e) {
                console.error('Error updating logo:', e);
                toast.error("Erreur lors de la modification du logo", {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: 'light',
                });
            }
        }
    };

    return (
        <div className="flex flex-col">
            <h2 className="text-xl text-slate-800 dark:text-slate-100 font-bold mb-8">Informations</h2>

            <Steps
                type="navigation"
                current={current}
                onChange={onChange}
                className="site-navigation-steps mb-12"
            >
                {steps.map((step) => (
                    <Steps.Step key={step.title} title={step.title} />
                ))}
            </Steps>

            <div className="w-2/3 mx-auto">
                {current === 0 && (
                    <FormManagementCompanyPart1
                        setCurrent={setCurrent}
                        current={current}
                        formData={formData}
                        setFormData={setFormData}
                        page={page}
                    />
                )}

                {current === 1 && (
                    <FormManagementCompanyPart2
                        setCurrent={setCurrent}
                        current={current}
                        formData={formData}
                        setFormData={setFormData}
                        page={page}
                    />
                )}

                {current === 2 && (
                    <FormManagementCompanyPart3
                        setCurrent={setCurrent}
                        current={current}
                        formData={formData}
                        setFormData={setFormData}
                        page={page}
                    />
                )}
            </div>

            <section className="mt-6">
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Modifier Logo</h2>

                <Form.Item name="avatar" className='mt-4'>
                    <Upload
                        beforeUpload={beforeUpload}
                        onChange={onFileChange}
                        customRequest={customRequest} // Dummy handler to disable auto-upload
                        fileList={imgList}
                        listType="picture-card"
                        maxCount={1}
                    >
                        {imgList.length >= 1 ? null : (
                            <div>
                                <PlusOutlined />
                                <div style={{ marginTop: 8 }}>Télécharger</div>
                            </div>
                        )}
                    </Upload>
                </Form.Item>
                <div className="mt-5">
                    <Button size="large" onClick={handleUpdateLogo}>
                        Modifier
                    </Button>
                </div>
            </section>

            <section className="mt-6">
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Page</h2>
                <div className="text-sm">Vous pouvez supprimer votre page.</div>
                <div className="mt-5">
                    <Button size="large" danger onClick={handleDeleteAccount}>
                        Supprimer page
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default Infos;
