import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import SearchAddress from '../../Utils/SearchAddress';
import { useDatas } from '../../../hooks/DatasContext';
import useAuthRequest from '../../../services/requests.service';
import { useAuth } from '../../../hooks/AuthProvider';
import { Bounce, toast } from 'react-toastify';

const { TextArea } = Input;

const CreatePageModal = ({ isModalOpen, setIsModalOpen, setIsLoading, setResponse }) => {
  const [step, setStep] = useState(1);
  const { datas } = useDatas();
  const request = useAuthRequest();

  const [typeCompany, setTypeCompany] = useState(0);
  const [form] = Form.useForm();
  const auth = useAuth();

  const [formData, setFormData] = useState({

  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const afterClose = () => {
    setStep(1);
    setTypeCompany(0);
    form.resetFields(); // Reset the form fields
  };

  const handleAddressChange = (fieldName, place) => {
    setFormData((prevData) => ({
        ...prevData,
        [fieldName]: place
    }));
    form.setFieldsValue({
        [fieldName]: place
    });
};
  
  

  const handleSubmitEvent = (values) => {
    const newFormData = { ...formData, ...values };
    console.log(newFormData)
    setFormData(newFormData);
    setStep(step + 1);
  };

  const handleSubmit = async (values) => {
    let data = { ...formData, ...values };
  
    data.typeCompany = "inde";
  
    if (data.isPartner) {
      data.typePage = "partner";
    } else {
      data.typePage = "company";
    }
  
    setIsLoading(true);
    try {
      let res = await request.postDataAPI('/page/', { ...data });
      setIsModalOpen(false);
  
      toast.success(res.msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
  
      setResponse(res);
    } catch (e) {
      console.error('Error in handleSubmit:', e); // Log the error for debugging
      toast.error(e.message || 'Une erreur est survenue.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } finally {
      setIsLoading(false);
    }
  };
  

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData, form]);

  return (
    <Modal
      centered
      bodyStyle={{ height: "80vh", maxHeight: "80vh", overflowY: "hidden" }}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      afterClose={afterClose}
    >


      {step === 1 && (
        <div className='flex flex-col justify-around w-full h-full py-12 gap-14 overflow-y-auto'>
          <h3 className='text-center font-semibold text-xl'>Génial ! Dîtes-moi en plus sur l’entreprise !</h3>
          <Form
            layout="vertical"
            style={{ width: "100%" }}
            form={form}
            initialValues={formData}
            onFinish={handleSubmitEvent}
          >
            {auth?.user?.user?.role ==="admin" &&<Form.Item
              name="isPartner"
              hasFeedback
    
            >
              <Checkbox name="isPartner" checked={formData.isPartner || false} onChange={(e) =>
                  setFormData({ ...formData, isPartner: e.target.checked })
                }>
                Je suis une page partenaire
              </Checkbox>
            </Form.Item>}
            
            <Form.Item
              label={typeCompany === 0 ? "Dénomination sociale de l’entreprise" : "Dénomination sociale de la maison mère*"}
              name="registeredName"
              hasFeedback
              rules={[
                { required: true, message: 'Entrez votre dénomination sociale!' },
                { max: 120, message: 'La dénomination sociale ne peut pas dépasser 120 caractères!' }
              ]}
            >
              <Input name="registeredName" size="large" maxLength={120} />
            </Form.Item>
            <Form.Item
              label="N° SIRET"
              name="siret"
              rules={[
                { required: true, message: 'Entrez votre SIRET!' },
                {
                  validator: (_, value) =>
                    value && /^\d{14}$/.test(value)
                      ? Promise.resolve()
                      : Promise.reject(new Error('SIRET doit contenir exactement 14 chiffres!'))
                }
              ]}
              hasFeedback
            >
              <Input name="siret" size='large' />
            </Form.Item>
            <Form.Item label="Adresse postale" name="postalAddress">
              <SearchAddress onAddressChange={handleAddressChange} address={formData.postalAddress ? formData.postalAddress : null} fieldName="postalAddress" />
            </Form.Item>
            
            <Form.Item className="flex justify-end mt-10">
              <Button size="large" shape="round" type="primary" htmlType="submit" className='ml-2'>Suivant</Button>
            </Form.Item>
          </Form>
        </div>
      )}

      {step === 2 && (
        <div className='flex flex-col justify-around w-full h-full py-12 gap-14 overflow-y-auto'>
          <h3 className='text-center font-semibold text-xl'>C’est parti, créons la page de votre établissement !</h3>
          <Form
            form={form}
            initialValues={formData}
            onFinish={handleSubmitEvent}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <Form.Item
              label="Nom d'établissement"
              name="name"
              hasFeedback
              rules={[
                { required: true, message: "Entrez le nom de l'entreprise!" },
                { max: 120, message: 'Le nom de l\'entreprise ne peut pas dépasser 120 caractères!' }
              ]}
            >
              <Input name="name" size="large" maxLength={120} />
            </Form.Item>
            <Form.Item name="sector" label="Type d'établissement" rules={[{ required: true, message: 'Sélectionnez le type!' }]}>
              <Select size="large" placeholder="Sélectionnez type" dropdownStyle={{ zIndex: "100000" }}>
                {datas.companyTypes.map((sector) => (
                  <Select.Option key={sector.id} value={sector.id}>{sector.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Adresse de l'établissement" name="address">
              <SearchAddress onAddressChange={handleAddressChange} address={formData.address ? formData.address : null} fieldName="address" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              hasFeedback
              rules={[
                { type: 'email', message: "Entrez une adresse email valide" },
                { max: 120, message: 'L\'email ne peut pas dépasser 120 caractères!' }
              ]}
            >
              <Input name="email" size="large" maxLength={120} />
            </Form.Item>
            <Form.Item
              label="Téléphone"
              name="phone"
              rules={[
                { pattern: /^0\d{9}$/, message: 'Le numéro de téléphone doit comporter 10 chiffres et commencer par 0' }
              ]}
              hasFeedback
            >
              <Input name="phone" size="large" />
            </Form.Item>
            <Form.Item
              label="Site web"
              name="website"
              rules={[
                { message: "Entrez le site web" },
                { max: 120, message: 'L\'adresse du site ne peut pas dépasser 120 caractères!' }
              ]}
            >
              <Input name="website" size="large" maxLength={120} />
            </Form.Item>
            <Form.Item className="flex justify-end mt-10">
              {step > 0 && <Button size="large" shape="round" onClick={() => setStep(1)}>Précèdent</Button>}
              <Button size="large" shape="round" type="primary" htmlType="submit" className='ml-2'>Suivant</Button>
            </Form.Item>
          </Form>
        </div>
      )}

      {step === 3 && (
        <div className='flex flex-col justify-around w-full h-full py-12 gap-14 overflow-y-auto'>
          <h3 className='text-center font-semibold text-xl'>C’est parti, créons la page de votre établissement !</h3>
          <Form
            form={form}
            initialValues={formData}
            onFinish={handleSubmit}
            layout="vertical"
            style={{ width: "100%" }}
          >
            <Form.Item
              label="Spécialités"
              name="tags"
            >
              <Select
                mode="tags"
                placeholder="Enter tags"
                size='large'
              />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Entrez une description!" },
                { max: 1000, message: 'La description ne peut pas dépasser 1000 caractères!' }
              ]}
              hasFeedback
            >
              <TextArea size='large' maxLength={1000} showCount />
            </Form.Item>
            <Form.Item className="flex justify-end mt-10">
              {step > 0 && <Button size="large" shape="round" onClick={() => setStep(2)}>Précèdent</Button>}
              <Button size="large" shape="round" type="primary" htmlType="submit" className='ml-2'>Créer mon compte</Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </Modal>
  );
};

export default CreatePageModal;
