import { Button, Form, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import commu from '../../../images/offers-icons/general.png';
import partner from '../../../images/offers-icons/target.png';
import { imageUpload } from '../../../utils/function';

const CreateOffer1 = ({ formData, setFormData, step, setStep, currentDeal}) => {

    const [form] = Form.useForm();
    form.setFieldsValue(formData);

 

    const submit = async () => {
        setStep(step+1);

      
    };

    const handleClick = (choice) => {
        setFormData({ ...formData, target: choice });
    };

    return (
        <div className='flex flex-col w-full h-full justify-between items-center'>
            <div className='flex flex-col'>
                <h2 className='font-semibold text-2xl text-center'>Créer une promotion</h2>
                <span className='text-lg text-center italic'>Pour qui est la promo?</span>
            </div>

            <div className='flex flex-col md:flex-row gap-4'>
                <div
                    onClick={() => handleClick("global")}
                    className={`relative flex flex-col gap-2 justify-center items-center p-4 rounded-md shadow-md hover:cursor-pointer hover:shadow-lg hover:scale-105 hover:transition-transform ${formData.target === 'global' ? 'bg-royalblue-300 ' : 'bg-royalblue-50'}`}
                    style={{ width: '150px', height: '150px' }}
                >
                    <img src={commu} alt={"behandy commu"} className='w-20' />
                    <span className='font-medium'>Communauté</span>

                    <Tooltip
                        className='absolute top-2 right-2'
                        title="Cette promo est destinée à l'ensemble de la communauté de Behandy. Parfait pour se faire connaître !"
                        zIndex={100000}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                            <path fill="currentColor" fillRule="evenodd" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14M5.5 9.375a.625.625 0 1 0 0 1.25h3a.625.625 0 1 0 0-1.25h-.875V6.5A.625.625 0 0 0 7 5.875H6a.625.625 0 1 0 0 1.25h.375v2.25zM8 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0" clipRule="evenodd" />
                        </svg>
                    </Tooltip>
                </div>

                <div
                    onClick={() => handleClick("partner")}
                    className={`relative flex flex-col gap-2 justify-center items-center p-4 rounded-md shadow-md hover:cursor-pointer hover:shadow-lg hover:scale-105 hover:transition-transform ${formData.target === 'partner' ? 'bg-royalblue-300 ' : 'bg-royalblue-50'}`}
                    style={{ width: '150px', height: '150px' }}
                >
                    <img src={partner} alt={"behandy commu"} className='w-20' />
                    <span className='font-medium'>Partenaires</span>

                    <Tooltip
                        className='absolute top-2 right-2'
                        title="Cette promo est réservée aux salariés des entreprises avec qui vous avez noué des partenariats sur Behandy. Gâtez-les !"
                        zIndex={100000}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14">
                            <path fill="currentColor" fillRule="evenodd" d="M7 14A7 7 0 1 0 7 0a7 7 0 0 0 0 14M5.5 9.375a.625.625 0 1 0 0 1.25h3a.625.625 0 1 0 0-1.25h-.875V6.5A.625.625 0 0 0 7 5.875H6a.625.625 0 1 0 0 1.25h.375v2.25zM8 4a1 1 0 1 1-2 0a1 1 0 0 1 2 0" clipRule="evenodd" />
                        </svg>
                    </Tooltip>
                </div>
            </div>

            <Button
                onClick={submit}
                className='flex self-end'
                size="large"
                type="primary"
                disabled={!formData.target} // Disable the button if no target is selected
            >
                Suivant
            </Button>
        </div>
    );
};

export default CreateOffer1;
