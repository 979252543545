import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';

import CreateOffer1 from './CreateOffer1';
import CreateOffer2 from './CreateOffer2';
import CreateOffer3 from './CreateOffer3';
import CreateOffer4 from './CreateOffer4';


const CreateOfferModal = ({page, isAdmin, isModalOpen, setIsModalOpen, setIsLoading, deals, setDeals, currentDeal , setCurrentDeal}) => {
  const [step, setStep] = useState(1);
 
  const [form] = Form.useForm();

  const [formData, setFormData] = useState({
    offer: '',
    address: null,
    description: '',
    tags: [],
    tagsDescription: []
  });


  useEffect(() => {
    if(currentDeal){
      console.log(currentDeal)
      setStep(1)
    }
  }, [currentDeal])
  
  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData, form]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const afterClose = () => {
    setStep(1);
    form.resetFields(); // Reset the form fields
    setFormData({})
    setCurrentDeal(null)
    console.log("close");
  };


  return (
    <Modal
      centered
      bodyStyle={{ height: "80vh", maxHeight: "80vh", overflowY: "hidden" }}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      afterClose={afterClose}
      
    >
      {
        step === 0 && <CreateOffer1 currentDeal={currentDeal} setDeals={setDeals} formData={formData} setFormData={setFormData} step={step} setStep={setStep}/>
      }

      {
        step === 3 && <CreateOffer2 isAdmin={isAdmin} currentDeal={currentDeal}  deals={deals} setDeals={setDeals} setIsModalOpen={setIsModalOpen} setIsLoading={setIsLoading} page={page} formData={formData} setFormData={setFormData} step={step} setStep={setStep}/>
      }

      {
        step === 1 && <CreateOffer3 currentDeal={currentDeal} page={page} formData={formData} setFormData={setFormData} step={step} setStep={setStep}/>
      }

      {
        step === 2 && <CreateOffer4 isAdmin={isAdmin} currentDeal={currentDeal} deals={deals} setDeals={setDeals} setIsModalOpen={setIsModalOpen} setIsLoading={setIsLoading} page={page} formData={formData} setFormData={setFormData} step={step} setStep={setStep}/>
      }

    </Modal>
  );
};

export default CreateOfferModal;
