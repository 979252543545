import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/AuthProvider.js';
import useAuthRequest from '../../../services/requests.service.js';
import EmployeesTable from './EmployeesTable.js';
import { getParamsFilter } from '../../../utils/function.js';
import { Card } from 'antd';

const Employees = ({page, setIsModalOpen}) => {
    const [subscriptions, setSubscriptions] = useState(null);
    const [type, setType] = useState(0);
    
    const  {id} = useParams();
    const auth = useAuth();
    const request = useAuthRequest();
  
    useEffect(() => {
      console.log("employees")
      getSubscriptions();
  
    }, [])
  
    const getSubscriptions = async () => {
      let newfilter = { company: page._id, status:"active" };
      let param = getParamsFilter(newfilter)

      let res = await request.getDataAPI(`/subscription?${param}`)
      setSubscriptions(res)
      console.log(res)
    }
  

  
  return (
    <div className='h-full m-4 md:w-full flex flex-col gap-6 '>
    
        <Card className='w-full bg-royalblue-100 '>
          <p className='text-royalblue-500'>Dans cet espace, vous pourrez retrouver vos salariés qui possèdent un abonnement Behandy. Pour gérer les abonnements de vos salariés (ajouter ou supprimer), rendez-vous dans l'onglet " gestion page".</p>
        </Card>
            {subscriptions?.subscriptions &&<EmployeesTable datas={subscriptions.subscriptions}/>}

      


    </div>
  )
}

export default Employees