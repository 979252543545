import React, { useEffect, useState } from 'react';
import useAuthRequest from '../../../services/requests.service';
import AccountsTable from './AccountsTable';
import { DotLoader } from 'react-spinners';
import { Select } from 'antd';

const AccountsSub = ({ page }) => {
  const request = useAuthRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionDatas, setSubscriptionsData] = useState(null);
  const [status, setStatus] = useState('active');
  const [currentPage, setCurrentPage] = useState(1); // State for current page
  const [pageSize, setPageSize] = useState(20); // State for page size

  useEffect(() => {
    getSubscriptions();
  }, [status, currentPage, pageSize]); // Add currentPage and pageSize as dependencies

  const getSubscriptions = async () => {
    setIsLoading(true); // Start loading
    try {
      const res = await request.getDataAPI(`subscription?subscriptionCompany=${page._id}&status=${status}&n=${pageSize}&page=${currentPage}`);
      setSubscriptionsData(res);
    } catch (error) {
      console.error('Failed to fetch subscriptions', error);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleStatusChange = (value) => {
    setStatus(value); // Update the status when the user selects a different option
    setCurrentPage(1); // Reset to the first page when status changes
  };

  return (
    <div>
      {isLoading ? (
        <div className="min-h-[200px] flex justify-center items-center">
          <DotLoader size={60} color="#6076E7" />
        </div>
      ) : (
        <div className="flex flex-col gap-5">
{ page.subscription&&         <div className="flex flex-col bg-royalblue-100 px-3 py-5 rounded-md">
            <span>
              Code d'activation: <b>{page.subscriptionCodes[0].code}</b>
            </span>
            <span>Nombre de comptes {status === 'active' ? "Actifs" : 'Non Actifs'}: {subscriptionDatas?.countActive}</span>
            <span>Nombre de comptes maximum: {page.subscription.max_account}</span>
          </div>
}
          <div className="flex justify-between mt-4">
            <h3 className='font-bold'>Comptes {status === 'active' ? 'Actifs' : 'Non Actifs'}</h3>
            <Select className="min-w-24" value={status} onChange={handleStatusChange}>
              <Select.Option value="active">Actif</Select.Option>
              <Select.Option value="cancelled">Non Actif</Select.Option>
            </Select>
          </div>

          <AccountsTable 
            page={page} 
            subscriptions={subscriptionDatas?.subscriptions}  
            setSubscriptionsData={setSubscriptionsData} 
            status={status}
            currentPage={currentPage}
            pageSize={pageSize}
            total={subscriptionDatas?.count} // Pass total count to the table
            onPageChange={(page, size) => {
              setCurrentPage(page);
              setPageSize(size);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default AccountsSub;
