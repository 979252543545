import React, { useState, useRef } from 'react';
import { Button, Input, Modal, Table } from 'antd';
import { MdDelete } from 'react-icons/md';
import { Bounce, toast } from 'react-toastify';
import useAuthRequest from '../../../services/requests.service';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const AccountsTable = ({ subscriptions, setSubscriptionsData, page, status, currentPage, pageSize, total, onPageChange }) => {
  const request = useAuthRequest();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [searchParams, setSearchParams] = useState({ name: '', email: '' }); // To store search parameters

  // Modify the getSubscriptions to include name and email as params
  const getSubscriptions = async (name = '', email = '') => {
    let res = await request.getDataAPI(`subscription?subscriptionCompany=${page._id}&status=${status}&n=${pageSize}&page=${currentPage}&name=${name}&email=${email}`);
    setSubscriptionsData(res);
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Rechercher`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 100, marginRight: 8 }}
        >
          Rechercher
        </Button>
        <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>
          Réinitialiser
        </Button>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  // Modify the handleSearch to store the search parameter and trigger getSubscriptions
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    const value = selectedKeys[0];
    setSearchText(value);
    setSearchedColumn(dataIndex);

    // Update the searchParams state based on the column being searched
    if (dataIndex === 'fullname') {
      setSearchParams((prev) => ({ ...prev, name: value }));
    } else if (dataIndex === 'email') {
      setSearchParams((prev) => ({ ...prev, email: value }));
    }

    // Call getSubscriptions with the updated searchParams
    getSubscriptions(
      dataIndex === 'fullname' ? value : searchParams.name,
      dataIndex === 'email' ? value : searchParams.email
    );
  };

  const handleReset = (clearFilters, dataIndex) => {
    clearFilters();
    setSearchText('');

    // Reset the searchParams state based on the column being reset
    if (dataIndex === 'fullname') {
      setSearchParams((prev) => ({ ...prev, name: '' }));
    } else if (dataIndex === 'email') {
      setSearchParams((prev) => ({ ...prev, email: '' }));
    }

    // Call getSubscriptions with empty search parameters
    getSubscriptions(
      dataIndex === 'fullname' ? '' : searchParams.name,
      dataIndex === 'email' ? '' : searchParams.email
    );
  };

  const columns = [
    {
      title: 'Nom/Prénom',
      dataIndex: 'fullname',
      key: 'fullname',
      ...getColumnSearchProps('fullname'),
      render: (_, sub) => (
        <span>{sub.user.firstname} {sub.user.lastname}</span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ...getColumnSearchProps('email'),
      render: (_, sub) => (
        <span>{sub.user.email}</span>
      ),
    },
    {
      title: 'Date Activation',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: 'hide-on-mobile',
      render: (createdAt) => (
        <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div style={{ display: "flex", gap: "5px" }}>
          <Button onClick={() => handleDeleteOk(record)} type="primary" danger size="large" icon={<MdDelete />} />
        </div>
      ),
    },
  ];

  const handleDeleteOk = (sub) => {
    const actionType = status === 'active' ? 'deactivateSubscription' : 'deleteSubscription';
    const actionTitle = status === 'active'
      ? 'Êtes-vous sûr de vouloir annuler l\'abonnement de ce compte?'
      : 'Êtes-vous sûr de vouloir supprimer cette ligne?';

    confirm({
      title: actionTitle,
      content: 'Cette action est irréversible',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      centered: true,
      async onOk() {
        try {
          const res = await request.deleteDataAPI(`/${actionType}/${sub._id}`);
          toast.success(res.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
          getSubscriptions();
        } catch (e) {
          console.error('Failed to delete subscription', e);
          toast.error(e.msg, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        }
      },
    });
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={subscriptions}
        rowKey="accounts"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: total,
          onChange: onPageChange,
        }}
      />
    </>
  );
};

export default AccountsTable;
