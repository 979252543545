import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import useAuthRequest from '../../../services/requests.service.js';
import { Modal, Tabs } from 'antd';
import Infos from './Infos.js';
import Admins from './Admins.js';
import { Bounce, toast } from 'react-toastify';


const Settings = ({ page, setPage, setIsLoading }) => {


  const { id } = useParams();
  const request = useAuthRequest();
  const [value, setValue] = useState(1);


  const onChange = (key) => {
    console.log(key);
    setValue(key)
  };


  const items = [
    {
      key: 1,
      label: 'Informations',
    },
    {
      key: 2,
      label: 'Administrateurs',
    },

  ];




  return (
    <div className='h-full m-4 md:w-full '>
      <Tabs defaultActiveKey={1} type="card" items={items} onChange={onChange} />
      {
        value === 1 && <Infos page={page} setIsLoading={setIsLoading} />
      }
      {
        value === 2 && <Admins page={page} setPage={setPage} setIsLoading={setIsLoading}/>
      }
    </div>
  )
}

export default Settings