import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../hooks/AuthProvider';
import useAuthRequest from '../../../services/requests.service';
import OffersTable from './OffersTable.js';
import { Button, Select } from 'antd';
import CreateOffer from './CreateOffer.js';

const Offers = ({page, deals, setDeals, setIsModalOpen, setCurrentDeal}) => {
    const [type, setType] = useState(0);
    
    const  {id} = useParams();
    const [status, setStatus] = useState('false');

    const auth = useAuth();
    const request = useAuthRequest();
  
    useEffect(() => {
  
      console.log("page offers")
      getDeals();
  
    }, [id,status])
  
    const getDeals = async () => {
      let res = await request.getDataAPI('/dealsByCompany/'+id+'?showPast='+status)
      setDeals(res)
      console.log(res)
    }
  

    const handleStatusChange = (value) => {
      setStatus(value); // Update the status when the user selects a different option
    };

  
  return (
    <div className='h-full m-4 md:w-full '>
      {type=== 0 &&  <>
             <div className="sm:flex sm:justify-end sm:items-center mb-4">

          
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">

                <Button size="large" type="primary" onClick={()=>setIsModalOpen(true)}>
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="hidden xs:block ml-2">Créer offre</span>
                </Button>
              </div>

  

            </div> 

            <div className="flex justify-end my-4">
            <Select className="min-w-24" value={status} onChange={handleStatusChange}>
              <Select.Option value="true">Offres expirées</Select.Option>
              <Select.Option value="false">Offres en cours</Select.Option>
            </Select>
          </div>
            {deals?.deals && <OffersTable setCurrentDeal={setCurrentDeal} datas={deals} deals={deals} setDeals={setDeals} setIsModalOpen={setIsModalOpen}/>}

        </>}
        {
            type === 1 && <div className='flex flex-col '>
             <div className="sm:flex sm:justify-between sm:items-center mb-4">
                <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">Créer offre</h1>
                </div>
            </div>

            <CreateOffer />
            
            
            
            </div>

        }

    </div>
  )
}

export default Offers