import { Button, Checkbox, DatePicker, Form, Input, Rate, Select, TimePicker, Upload } from 'antd';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import locale from 'antd/es/date-picker/locale/fr_FR';
import { useDatas } from '../../../hooks/DatasContext';
import { FaUserAlt } from 'react-icons/fa';
import { validateURL } from '../../../utils/function';
import useAuthRequest from '../../../services/requests.service';
import { Bounce, toast } from 'react-toastify';
import TextArea from 'antd/es/input/TextArea';

const CreateOffer4 = ({ isAdmin, step, setStep, formData, setFormData, currentDeal, setIsLoading, setIsModalOpen, deals, setDeals, page }) => {
  const [form] = Form.useForm();
  const [isPerma, setIsPerma] = useState(formData.isPerma || false);
  const [activeMode, setActiveMode] = useState(formData.mode || '');

  const [fileList, setFileList] = useState(formData.filePdf || []);
  const request = useAuthRequest();
  const [isNonPreciseTimeChecked, setIsNonPreciseTimeChecked] = useState(formData.isNonPreciseTimeChecked || false);
  const { datas } = useDatas();

  const days = datas?.days.map((filter) => ({ value: filter.id, label: filter.name }));


  useEffect(() => {
    if (currentDeal) {
      const mergedData = {
        ...formData,
        startdate: currentDeal.startdate ? dayjs(currentDeal.startdate, 'YYYY-MM-DD') : '',
        enddate: currentDeal.enddate !== '2099-12-31' ? dayjs(currentDeal.enddate, 'YYYY-MM-DD') : '',
        starttime: currentDeal.starttime ? dayjs(currentDeal.starttime, 'HH:mm') : '',
        endtime: currentDeal.endtime ? dayjs(currentDeal.endtime, 'HH:mm') : '',
      };
      setFormData(mergedData);
      setIsPerma(currentDeal.enddate === '2099-12-31' || false);
      setIsNonPreciseTimeChecked((currentDeal.starttime === '' && currentDeal.endtime === '') || false);
      setActiveMode(currentDeal.mode || '');
      setFileList(currentDeal.filePdf || []); 
      form.setFieldsValue(mergedData);
    } else {
      form.setFieldsValue({
        ...formData,
        startdate: formData.startdate ? dayjs(formData.startdate, 'YYYY-MM-DD') : '',
        enddate: formData.enddate ? dayjs(formData.enddate, 'YYYY-MM-DD') : '',
        starttime: formData.starttime ? dayjs(formData.starttime, 'HH:mm') : '',
        endtime: formData.endtime ? dayjs(formData.endtime, 'HH:mm') : '',
      });
    }
  }, [currentDeal]);

  const handleDateChange = (date, dateString, field) => {
    const updatedFormData = {
      ...formData,
      [field]: date ? dayjs(date).format('YYYY-MM-DD') : '', // Ensure proper date format
    };
    setFormData(updatedFormData);
  };
  
  const handleTimeChange = (time, timeString, field) => {
    const updatedFormData = {
      ...formData,
      [field]: time ? dayjs(time).format('HH:mm') : '', // Ensure proper time format
    };
    setFormData(updatedFormData);
  };
  

  const handleCheckboxPermaChange = (e) => {
    setIsPerma(e.target.checked);
    const updatedFormData = {
      ...formData,
      isPerma: e.target.checked,
      startdate: e.target.checked ? '' : formData.startdate,
      enddate: e.target.checked ? '' : formData.enddate,
    };
    setFormData(updatedFormData);

    form.setFieldsValue({
      startdate: updatedFormData.startdate ? dayjs(updatedFormData.startdate, 'YYYY-MM-DD') : '',
      enddate: updatedFormData.enddate ? dayjs(updatedFormData.enddate, 'YYYY-MM-DD') : '',
    });
  };

  const handleCheckboxTimeChange = (e) => {
    setIsNonPreciseTimeChecked(e.target.checked);
    const updatedFormData = {
      ...formData,
      isNonPreciseTimeChecked: e.target.checked,
      starttime: e.target.checked ? '' : formData.starttime,
      endtime: e.target.checked ? '' : formData.endtime,
    };
    setFormData(updatedFormData);

    form.setFieldsValue({
      starttime: updatedFormData.starttime ? dayjs(updatedFormData.starttime, 'HH:mm') : '',
      endtime: updatedFormData.endtime ? dayjs(updatedFormData.endtime, 'HH:mm') : '',
    });
  };

  const handleClick = (choice) => {
    setActiveMode(choice);
    setFormData({ ...formData, mode: choice });
  };

  const getDeals = async () => {
    let res = await request.getDataAPI(isAdmin?`dealsAll`:`/dealsByCompany/${page._id}`)
    setDeals(res)
    console.log(res)
  }

  const onFileChange = ({ file, fileList: newFileList }) => {
    if (newFileList.length === 0) {
      setFileList([]);
      setFormData({ ...formData, filePdf: [] });
    } else {
      setFileList([file]);
      setFormData({ ...formData, filePdf: [file] });
    }
  };

  const imageUpload = async (images) => {
    console.log("image upload");
    let imgArr = [];

    for (const item of images) {
        if(item.url)return;
        const formDataS3 = new FormData();
        formDataS3.append("image", item.originFileObj);

        console.log(JSON.stringify(formDataS3))
        try {
            const resS3 = await request.postDataAPIWithoutToken('uploadImage', formDataS3);
            console.log(resS3)
            imgArr.push({ url: resS3.uploadLocation });
        } catch (error) {
            console.error("Image upload failed:", error);
        }
    }



    return imgArr;
};

const pdfUpload = async (pdfFiles) => {
    console.log("PDF upload");
    console.log(pdfFiles)
    let pdfArr = [];

    for(const item of pdfFiles){
      if(item.url)return;

        const formDataS3 = new FormData();    
        // Directly append the PDF file to formData
        formDataS3.append("file", item, item.name);
        
        // Here, adjust the parameters as necessary for your backend requirements
        formDataS3.append("upload_preset", "bb5qshs3"); // Adjust or remove if not needed
        formDataS3.append("cloud_name", "behandy"); // Adjust or remove if not needed

        console.log(formDataS3)
        // Replace 'uploadFile' with your actual backend endpoint for file uploads
        const resS3 = await request.postDataAPIWithoutToken('uploadFile', formDataS3);
        console.log(resS3)
        // Ensure the response handling matches your backend's response structure
        pdfArr.push({url: resS3.uploadLocation});
    }

    return pdfArr;
}


  const onFinish = async () => {
    const formattedData = {
      ...formData,
      startdate: formData.startdate ? dayjs(formData.startdate).format('YYYY-MM-DD') : '',
      enddate: formData.enddate ? dayjs(formData.enddate).format('YYYY-MM-DD') : '2099-12-31',
      starttime: formData.starttime ? dayjs(formData.starttime).format('HH:mm') : '',
      endtime: formData.endtime ? dayjs(formData.endtime).format('HH:mm') : '',
    };
  
    if (!currentDeal) {
      setStep(step + 1);
    } else {

      setIsLoading(true)
      if (formData.images && formData.images.length > 0) {
        let media = await imageUpload(formData.images)
        console.log(media)
        formattedData.images = media
      }

      if (formData.filePdf && formData.filePdf.length > 0) {
          let media = await pdfUpload(formData.filePdf)
          formattedData.filePdf = media
      }

      console.log(formattedData)


      let res = await request.patchDataAPI('/deal/' + formData._id, formattedData);
      setIsModalOpen(false);
      setIsLoading(false)

      getDeals();
  
      toast.success('Offre mise-à-jour!', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    }
  };
  

  return (
    <div className="flex flex-col w-full h-full justify-between items-center">
      <div className="flex flex-col">
        <h2 className="font-semibold text-2xl text-center">{currentDeal ? 'Mis-à-jour promotion' : 'Créer une promotion'}</h2>
        <span className="text-lg text-center italic">Modalités de l'offre</span>
      </div>
      {JSON.stringify(formData.offer)}

      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        onValuesChange={(changedValues, allValues) => setFormData({ ...formData, ...allValues })}
        className="mt-10"
        style={{ width: '100%', overflowY: 'auto' }}
      >
        <Form.Item
          label="Période de validité"
          style={{ marginBottom: '24px' }}
        >
          <Form.Item
            name="startdate"
            rules={[{ required: !isPerma, message: 'Entrez une date de début!' }]}
            style={{ display: 'inline-block', marginBottom: '0px', width: 'calc(50% - 8px)' }}
          >
            <DatePicker
              placeholder="Début"
              style={{ width: '100%' }}
              size="large"
              locale={locale}
              format="YYYY-MM-DD"
              onChange={(date, dateString) => handleDateChange(date, dateString, 'startdate')}
            />
          </Form.Item>
          <Form.Item
            name="enddate"
            rules={[{ required: !isPerma, message: 'Entrez une date de fin!' }]}
            style={{ display: 'inline-block', marginBottom: '0px', width: 'calc(50% - 8px)', margin: '0 8px' }}
          >
            <DatePicker
              placeholder="Fin"
              size="large"
              style={{ width: '100%' }}
              locale={locale}
              format="YYYY-MM-DD"
              onChange={(date, dateString) => handleDateChange(date, dateString, 'enddate')}
            />
          </Form.Item>

          <Checkbox onChange={handleCheckboxPermaChange} checked={isPerma}>
            Promo permanente (jusqu'à sa suppression)
          </Checkbox>
        </Form.Item>

        <Form.Item label="Jours de validité" name="date" hasFeedback>
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Choississez les jours"
            options={days}
            size="large"
            dropdownStyle={{ zIndex: '100000' }}
          />
        </Form.Item>

        <Form.Item
          label="Horaire de validité"
          style={{ marginBottom: '24px' }}
        >
          <Form.Item
            name="starttime"
            rules={[{ required: !isNonPreciseTimeChecked, message: 'Entrez une horaire de début!' }]}
            style={{ display: 'inline-block', marginBottom: '0px', width: 'calc(50% - 8px)' }}
          >
            <TimePicker
              format={'HH:mm'}
              placeholder="Début"
              locale={locale}
              style={{ width: '100%' }}
              size="large"
              onChange={(time, timeString) => handleTimeChange(time, timeString, 'starttime')}
            />
          </Form.Item>
          <Form.Item
            name="endtime"
            rules={[{ required: !isNonPreciseTimeChecked, message: 'Entrez une horaire de fin!' }]}
            style={{ display: 'inline-block', width: 'calc(50% - 8px)', margin: '0 8px' }}
          >
            <TimePicker
              format={'HH:mm'}
              placeholder="Fin"
              locale={locale}
              style={{ width: '100%' }}
              size="large"
              onChange={(time, timeString) => handleTimeChange(time, timeString, 'endtime')}
            />
          </Form.Item>
          <Checkbox onChange={handleCheckboxTimeChange} checked={isNonPreciseTimeChecked}>
            Non précisé
          </Checkbox>
        </Form.Item>

        <Form.Item
          label="Nombre de personnes bénéficiant de l'offre (salarié inclus)"
          name="amountPersons"
          hasFeedback
        >
          <div className="rate">
            <Rate
              character={<FaUserAlt />}
              count={8}
              style={{ fontSize: 20, color: 'var(--color-accent)' }}
              onChange={(value) => setFormData({ ...formData, amountPersons: value })}
              value={formData.amountPersons || 0}
            />
          </div>
        </Form.Item>

        <Form.Item
          label="Site internet"
          placeholder="https://votresite.com"
          hasFeedback
          name="website"
          rules={[
            {
              validator: validateURL,
              message: 'Entrez une URL valide!',
            },
          ]}
        >
          <Input size="large" maxLength={120} />
        </Form.Item>

        <Form.Item
          label="Comment utiliser la promo (1 case max)"
          required={true}
          rules={[
            {
              validator: (_, value) =>
                activeMode ? Promise.resolve() : Promise.reject(new Error('Veuillez choisir une option!')),
            },
          ]}
        >
          <div className="flex flex-col gap-2">
            <div
              className={`px-3 py-3 rounded-md text-center  font-semibold hover:cursor-pointer ${
                activeMode === 'noreservation' ? 'bg-royalblue-500 hover:bg-royalblue-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
              }`}
              onClick={() => handleClick('noreservation')}
            >
              Pas de réservation nécessaire
            </div>

            <div
              className={`px-3 py-3 rounded-md text-center font-semibold hover:cursor-pointer ${
                activeMode === 'phone' ? 'bg-royalblue-500 hover:bg-royalblue-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
              }`}
              onClick={() => handleClick('phone')}
            >
              Réservation par téléphone
            </div>
            {activeMode === 'phone' && (
              <Form.Item
                label="Numéro de téléphone"
                name="modeInfos"
                rules={[
                  {
                    required: true,
                    message: 'Entrez votre numéro de téléphone!',
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
            )}

            <div
              className={`px-3 py-3 rounded-md text-center font-semibold hover:cursor-pointer ${
                activeMode === 'email' ? 'bg-royalblue-500 hover:bg-royalblue-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
              }`}
              onClick={() => handleClick('email')}
            >
              Réservation par email
            </div>
            {activeMode === 'email' && (
              <Form.Item
                label="Email"
                name="modeInfos"
                rules={[
                  {
                    required: true,
                    message: 'Entrez votre email!',
                  },
                  {
                    max: 140,
                    message: "L'email ne peut pas dépasser 140 caractères!",
                  },
                ]}
              >
                <Input size="large" maxLength={140} />
              </Form.Item>
            )}

            <div
              className={`px-3 py-3 rounded-md text-center font-semibold hover:cursor-pointer ${
                activeMode === 'code' ? 'bg-royalblue-500 hover:bg-royalblue-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
              }`}
              onClick={() => handleClick('code')}
            >
              Code promo à utiliser en ligne
            </div>
            {activeMode === 'code' && (
              <Form.Item
                label="Code de réservation"
                name="modeInfos"
                rules={[
                  {
                    required: true,
                    message: 'Entrez votre code de réservation!',
                  },
                  {
                    max: 50,
                    message: 'Le code ne peut pas dépasser 50 caractères!',
                  },
                ]}
              >
                <Input size="large" maxLength={50} />
              </Form.Item>
            )}

            <div
              className={`px-3 py-3 rounded-md text-center font-semibold hover:cursor-pointer ${
                activeMode === 'link' ? 'bg-royalblue-500 hover:bg-royalblue-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
              }`}
              onClick={() => handleClick('link')}
            >
              Réservation via un lien
            </div>
            {activeMode === 'link' && (
              <Form.Item
                label="Lien de réservation"
                name="modeInfos"
                rules={[
                  {
                    required: true,
                    message: 'Entrez votre lien!',
                  },
                  {
                    max: 200,
                    message: 'Le lien ne peut pas dépasser 200 caractères!',
                  },
                ]}
              >
                <Input size="large" maxLength={200} />
              </Form.Item>
            )}

            <div
              className={`px-3 py-3 rounded-md text-center font-semibold hover:cursor-pointer ${
                activeMode === 'other' ? 'bg-royalblue-500 hover:bg-royalblue-400 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-800'
              }`}
              onClick={() => handleClick('other')}
            >
              Réservation via un autre moyen
            </div>
            {activeMode === 'other' && (
              <>
              <Form.Item
                label="Autre"
                name="modeInfos"
                rules={[
                  {
                    required: true,
                    message: 'Entrez votre moyen!',
                  },
                  {
                    max: 200,
                    message: 'Le texte ne peut pas dépasser 200 caractères!',
                  },
                ]}
              >
                <TextArea size="large" maxLength={200} />
              </Form.Item>

              </>
            )}
          </div>
        </Form.Item>

        <Form.Item
          label="Ajouter un pdf"
          name="pdfFile"
          rules={[
            {
              validator: (_, value) => {
                if (!value || value.fileList.length === 0) {
                  return Promise.resolve();
                }
                const file = value.file;
                const isPdf = file.type === 'application/pdf';
                const isLessThan5MB = file.size / 1024 / 1024 < 3;
                if (!isPdf) {
                  return Promise.reject(new Error('Le fichier doit être un PDF.'));
                }
                if (!isLessThan5MB) {
                  return Promise.reject(new Error('Le fichier ne doit pas dépasser 3MB.'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Upload
            beforeUpload={() => false}
            onChange={onFileChange}
            multiple={false}
            fileList={fileList}
            accept=".pdf"
          >
            <Button>Sélectionnez un pdf</Button>
          </Upload>
        </Form.Item>

        <Form.Item className="flex justify-end mt-10">
          <Button size="large" onClick={() => setStep(step - 1)}>
            Précèdent
          </Button>
          <Button size="large" type="primary" htmlType="submit" className="ml-2">
            {currentDeal ? 'Mettre-à-jour' : 'Suivant'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateOffer4;
