import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";

const ConditionalRoute = () => {
  const { user, loading } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return <div>Loading...</div>; // or any loading spinner component
  }

  if (user) {
    navigate("/dashboard");
    return <Dashboard />;
  } else {
    return <Login />;
  }
};

export default ConditionalRoute;
