import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import SearchAddress from '../../../Utils/SearchAddress';
import { useDatas } from '../../../../hooks/DatasContext';

const FormManagementCompanyPart2 = ({ current, setCurrent, formData, setFormData, page }) => {
  const [form] = Form.useForm();
  const {datas} = useDatas();

  // Pre-fill form with data from formData
  form.setFieldsValue(formData);

  const onFinish = (values) => {
    setFormData({ ...formData, ...values }); // Update the main formData state
    setCurrent(current + 1); // Move to the next step 
    console.log(formData)
  };

  const handleAddressChange = (fieldName, place) => {
    setFormData((prevData) => ({
      ...prevData,
      [fieldName]: place
    }));
    form.setFieldsValue({
      [fieldName]: place
    });
  };


  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ width: '100%' }}
    >
      <Form.Item
        label="Nom d'établissement"
        name="name"
        hasFeedback

        rules={[
            {
                required: true,
                message: "Entrez le nom de l'entreprise!",

            },
            {
                max: 120,
                message: 'Le nom de l\'entreprise ne peut pas dépasser 120 caractères!',
              }
        ]}
    >
        <Input name="name" size="large" maxLength={120} />
      </Form.Item>


      <Form.Item name="sector" label="Type d'établissement" rules={[{ required: true, message: 'Sélectionnez le type!', }]}>
          <Select size="large" placeholder="Sélectionnez type" dropdownStyle={{ zIndex: "100000" }}>

              {
                  datas?.companyTypes.map((sector) => (
                      <Select.Option value={sector.id}>{sector.name} {sector.value}</Select.Option>

                  ))
              }
          </Select>
      </Form.Item>





    <Form.Item label="Adresse de l'établissement" name="address">
              <SearchAddress onAddressChange={handleAddressChange} address={formData.address ? formData.address : null} fieldName="address" />
            </Form.Item>


    <Form.Item
        label="Email"
        name="email"
        hasFeedback
        rules={[
     
            {
                type: 'email',
                message: "Entrez une adresse email valide",
            },
            {
                max: 120,
                message: 'L`\'email ne peut pas dépasser 120 caractères!',
              }
        ]}
    >
        <Input name="email" size="large" maxLength={120}/>
    </Form.Item>


    <Form.Item
        label="Téléphone"
        name="phone"
        rules={[
   
            {
                pattern: /^0\d{9}$/,
                message: 'Le numéro de téléphone doit comporter 10 chiffres et commencer par 0',
            }
        ]}
        hasFeedback
    >
        <Input name="phone" size="large" />
    </Form.Item>


    <Form.Item
        label="Site web"
        name="website"


        rules={[
            {
                message: "Entrez le site web",
            },
            {
                max: 120,
                message: 'L\' adresse du site ne peut pas dépasser 120 caractères!',
              }
        ]}


    >
        <Input name="website" size="large" maxLength={120}/>
    </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '35px' }}>
        <Button size="large" shape="round" type="primary" htmlType="submit">
          Suivant
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormManagementCompanyPart2;
