import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import TextArea from 'antd/es/input/TextArea';
import { useDatas } from '../../../../hooks/DatasContext';
import useAuthRequest from '../../../../services/requests.service';
import { Bounce, toast } from 'react-toastify';

const FormManagementCompanyPart3 = ({ current, setCurrent, formData, setFormData }) => {
  const [form] = Form.useForm();
  const { datas } = useDatas();
  const request = useAuthRequest();
  // Pre-fill form with data from formData
  form.setFieldsValue(formData);

  const onFinish = async (values) => {
    let newDatas = {...formData, ...values}
    setFormData(newDatas); // Update the main formData state

    try{
      let res = await request.patchDataAPI('/page/'+formData._id, { ...newDatas });
      toast.success(res.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    }catch(e){
      toast.error(e.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    }

    console.log(formData);
  };

  const handleTagsChange = (value) => {
    setFormData({ ...formData, specialities: value });
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
      style={{ width: '100%' }}
    >
      <Form.Item
        label="Spécialités"
        name="specialities"
      >
        <Select
          mode="tags"
          placeholder="Entrez vos spécialités"
          size='large'
          value={formData.specialities}
          onChange={handleTagsChange}
        >
          {/* Optionally, you can add predefined options here */}
          {datas?.specialities?.map((speciality) => (
            <Select.Option key={speciality} value={speciality}>
              {speciality}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Description"
        name="description"
        rules={[
          {
            required: true,
            message: "Entrez une description!",
          },
          {
            max: 1000,
            message: 'La description ne peut pas dépasser 1000 caractères!',
          }
        ]}
        hasFeedback
      >
        <TextArea name="description" size='large' maxLength={1000} showCount />
      </Form.Item>

      <Form.Item style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '35px' }}>
        <Button size="large" shape="round" type="primary" htmlType="submit">
          Sauvegarder
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormManagementCompanyPart3;
