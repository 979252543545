import { Button, Form, Input,Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { useAuth } from '../../../hooks/AuthProvider';
import useAuthRequest from '../../../services/requests.service';
import { Bounce, toast } from 'react-toastify';
const { confirm } = Modal;

const MyAccount = () => {
    const [form] = Form.useForm();
    const [passwordForm] = Form.useForm();
    const [activateForm] = Form.useForm();

    const { user } = useAuth();
    const requestAuth = useAuth();
    const request = useAuthRequest();
    const [changePassword, setChangePassword] = useState(false);
    const [activateSub, setActivateSub] = useState(false);

    // Step 1: Define the relevant form field names
    const formFields = ['fullname', 'email', 'mobile', 'companyName', 'titleJob'];

    // Step 2: Initialize formData with only the relevant fields from user.user
    const initializeFormData = () => {
        const initialData = {};
        formFields.forEach((field) => {
            if (user.user && user.user[field] !== undefined) {
                initialData[field] = user.user[field];
            }
        });
        return initialData;
    };

    const [formData, setFormData] = useState(initializeFormData);

    // If user.user might change after initial render, update formData accordingly
    useEffect(() => {
        setFormData(initializeFormData());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.user]);

    // Populate the form fields whenever formData changes
    useEffect(() => {
        form.setFieldsValue(formData);
    }, [formData, form]);

    // Handle changes in form fields
    const onValuesChange = (changedValues, allValues) => {
        setFormData((prevData) => ({
            ...prevData,
            ...allValues,
        }));
    };

    // Handle form submission for general information
    const onFinish = async () => {
        try {
            console.log('Final Form Data:', formData);

            let updatedUser = { ...user.user, ...formData };
            requestAuth.updateUser(updatedUser);

            let res = await request.patchDataAPI('/user', { ...formData });

            toast.success('Information mise-à-jour!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        } catch (e) {
/*             toast.error('Une erreur est survenue lors de la mise à jour!', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            }); */
            console.error('Update error:', e);
        }
    };

    // Handle form submission for password change
    const onChangePassword = async (values) => {
        try {
            // Implement your password change logic here
            console.log('Password Change Data:', values);

            // Example: Send request to update password
            let res = await request.postDataAPI('/user/resetPassword',{password: values.password});

            setChangePassword(false);

            toast.success(res.msg, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        } catch (e) {
            toast.error(e.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
            console.error('Password update error:', e);
        }
    };

    const onActivateSub = async (values) =>{
        try {
            // Implement your password change logic here
            console.log(' Change Data:', values);

            // Example: Send request to update password
            let res = await request.postDataAPI('activateSubscription',{code: values.code});

            setActivateSub(false);
            let updatedUser = { ...user.user, subscription: res.subscription };
            requestAuth.updateUser(updatedUser);
            toast.success(res.msg, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        } catch (e) {
             toast.error(e.message, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            }); 
            console.error('Activation error:', e);
        }
    }

    const handleDeleteSub=  (record) => {
        confirm({
          title: 'Êtes-vous sûr de vouloir supprimer votre abonnement?',
          content: 'Cette action est irréversible',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Non',
          centered: true,

         async onOk() {
    
            try{

                const res = await request.deleteDataAPI(`/deactivateSubscription/${user.user.subscription._id}`)


                let updatedUser = { ...user.user, subscription:null };

                console.log(updatedUser)
                requestAuth.updateUser(updatedUser);
                 toast.success(res.msg, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  transition: Bounce,
                  }); 
    
            }catch(e){
                 toast.error(e.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    }); 
            }
    
          },
        });
      };

    const handleDeleteAccount = (record) => {
        confirm({
          title: 'Êtes-vous sûr de vouloir supprimer votre compte?',
          content: 'Cette action est irréversible',
          okText: 'Oui',
          okType: 'danger',
          cancelText: 'Non',
          centered: true,
          async onOk() {
    
            try{

                const res = await request.deleteDataAPI(`/user/delete`)
                
                toast.success(res.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    });
                requestAuth.logout();
     

    
            }catch(e){
                 toast.error(e.msg, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    }); 
            }
    
          },
        });
      };

    return (
        <div className="h-full m-4 md:w-full">
            <section>
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Mes infos</h2>
                <div className="text-sm">Vous pouvez voir et modifier les informations de votre compte.</div>
                <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    onValuesChange={onValuesChange}
                    className="mt-10"
                    style={{ width: '100%', overflowY: 'auto' }}
                >
                    <Form.Item
                        label="Nom/Prénom"
                        name="fullname"
                        rules={[
                            {
                                required: true,
                                message: 'Entrez le nom et prénom!',
                            },
                            {
                                max: 140,
                                message: 'Le nom et prénom ne peuvent pas dépasser 140 caractères!',
                            },
                        ]}
                    >
                        <Input size="large" maxLength={140} disabled />
                    </Form.Item>

                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                            {
                                type: "email",
                                required: true,
                                message: 'Veuillez entrer un email valide!',
                            },
                            {
                                max: 140,
                                message: 'L\'email ne peut pas dépasser 140 caractères!',
                            },
                        ]}
                    >
                        <Input size="large" maxLength={140} />
                    </Form.Item>

                    <Form.Item
                        label="Téléphone"
                        name="mobile"
                        rules={[
                            {
                                required: true,
                                message: "Entrez le numéro de téléphone",
                            },
                            {
                                pattern: /^0\d{9}$/,
                                message: 'Le numéro de téléphone doit comporter 10 chiffres et commencer par 0',
                            },
                        ]}
                        hasFeedback
                    >
                        <Input name="mobile" size="large" />
                    </Form.Item>

                    <Form.Item
                        label="Entreprise"
                        name="companyName"
                        rules={[
                            {
                                max: 140,
                                message: 'Le nom de l\'entreprise ne peut pas dépasser 140 caractères!',
                            },
                        ]}
                    >
                        <Input size="large" maxLength={140} />
                    </Form.Item>

                    <Form.Item
                        label="Poste"
                        name="titleJob"
                        rules={[
                            {
                                max: 140,
                                message: 'Le poste ne peut pas dépasser 140 caractères!',
                            },
                        ]}
                    >
                        <Input size="large" maxLength={140} />
                    </Form.Item>

                    <Form.Item className="flex justify-end mt-10">
                        <Button size="large" type="primary" htmlType="submit" className="ml-2">
                            Modifier
                        </Button>
                    </Form.Item>
                </Form>
            </section>

            <section className="mt-6">
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Mot de passe</h2>
                <div className="text-sm">Vous pouvez définir un mot de passe permanent si vous ne souhaitez pas utiliser de codes de connexion temporaires.</div>
                <div className="mt-5">
                    {changePassword ? (
                        <Form
                            form={passwordForm}
                            onFinish={onChangePassword}
                            layout="vertical"
                            className="mt-10"
                            style={{ width: '100%', overflowY: 'auto' }}
                        >
                            <Form.Item
                                label="Mot de passe"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entrez votre mot de passe!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input.Password size="large" />
                            </Form.Item>

                            <Form.Item
                                label="Confirmation du mot de passe"
                                name="cf_password"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entrez le mot de passe de confirmation!',
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Les mots de passe ne correspondent pas!'));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password size="large" />
                            </Form.Item>

                            <Form.Item className="flex justify-end mt-10">
                                <Button size="large" onClick={() => setChangePassword(false)}>
                                    Annuler
                                </Button>
                                <Button size="large" type="primary" htmlType="submit" className="ml-2">
                                    Modifier
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        <Button size="large" onClick={() => setChangePassword(true)}>
                            Changer mot de passe
                        </Button>
                    )}
                </div>
            </section>

            <section className="mt-6">
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Abonnement </h2>
                { user?.user?.subscription?.status !== "active" && <> <div className="text-sm ">{activateSub?"Rentre le code que tu as reçu de la part de ton employeur":"Vous n'avez aucun abonnement actuellement."}</div>
                {activateSub ? (
                        <Form
                            form={activateForm}
                            onFinish={onActivateSub}
                            layout="vertical"
                            className="mt-10"
                            style={{ width: '100%', overflowY: 'auto' }}
                        >
                            <Form.Item
                                label="Code d'activation"
                                name="code"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Entrez votre code!',
                                    },
                                ]}
                                hasFeedback
                            >
                                <Input size="large" />
                            </Form.Item>


                            <Form.Item className="flex justify-end mt-10">
                                <Button size="large" onClick={() => setActivateSub(false)}>
                                    Annuler
                                </Button>
                                <Button size="large" type="primary" htmlType="submit" className="ml-2">
                                    Activer
                                </Button>
                            </Form.Item>
                        </Form>
                    ) : (
                        <Button className='mt-5' size="large" onClick={() => setActivateSub(true)}>
                            Activer Abonnement
                        </Button>
                    )}

                </>}
              { user?.user?.subscription?.status === "active" && <> <div className="text-sm">Gérez votre abonnement.</div>
                <div className="mt-5">
                    <Button size="large" onClick={handleDeleteSub}>
                        Supprimer abonnement
                    </Button>
                </div></>}
            </section>

            <section className="mt-6">
                <h2 className="text-xl leading-snug text-slate-800 dark:text-slate-100 font-bold mb-1">Compte</h2>
                <div className="text-sm">Vous pouvez supprimer votre compte.</div>
                <div className="mt-5">
                    <Button size="large" danger onClick={handleDeleteAccount}>
                        Supprimer compte
                    </Button>
                </div>
            </section>
        </div>
    );
};

export default MyAccount;
