import { Button, Space, Table, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import AdminsTable from './Admin/AdminsTable';
import AddAdminModal from './Admin/AddAdminModal';

const Admins = ({page, setPage, setIsLoading}) => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [response, setResponse] = useState(null);
    
 
    

    return (
        <div className='h-full  md:w-full '>
            <div className="sm:flex sm:justify-end sm:items-center mb-4">
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                    <Button size="large" type="primary" onClick={() => setIsModalOpen(true)}>
                        <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                        </svg>
                        <span className="hidden xs:block ml-2">Ajouter admin</span>
                    </Button>
                </div>

            </div>
            <AdminsTable page={page} setPage={setPage} setIsLoading={setIsLoading} />

            <AddAdminModal page={page} setPage={setPage} setIsLoading={setIsLoading} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} setResponse={setResponse}/>


        </div>
    )
}

export default Admins