import { Button, Space, Table, Tag } from 'antd';
import React from 'react'

const EmployeesTable = ({datas}) => {


    const CustomHeader = (props) => {
        return (
          <th {...props} className="bg-blue-500 text-white p-2 text-left">
            {props.children}
          </th>
        );
      };
  
    const columns = [
        {
          title: 'Nom',
          dataIndex: 'user',
          key: 'fullname',
          render: (text) => <span>{text.fullname}</span>,
        },
   

        {
          title: 'Email',
          dataIndex: 'user',
          key: 'email',
          render: (text) => <span>{text.email}</span>,
        },
   
      ];
    
  
    
  return (
    <Table columns={columns} dataSource={datas}         className="bg-white dark:bg-slate-800  shadow rounded-lg"     components={{
        header: {
          cell: CustomHeader,
        },
      }}/>
  )
}

export default EmployeesTable