import { Tabs } from 'antd';
import React, { useState } from 'react'
import FiltersCategories from './FiltersCategories/FiltersCategories';
import OffersAll from './OffersManagement/OffersAll';
import ReportDeals from './Reports/ReportDeals';

const Offers = ({setIsLoading}) => {

    const [value, setValue] = useState(1);


    const onChange = (key) => {
        console.log(key);
        setValue(key)
    };


    const items = [

        {
            key: 1,
            label: 'Offres'
        },
        {
            key: 2,
            label: 'Signalements'
        }


    ];
    return (
        <div className='h-full m-4 md:w-full '>
            <Tabs defaultActiveKey={1} type="card" items={items} onChange={onChange} />
    {/*         {
            value === 1 && <FiltersCategories  setIsLoading={setIsLoading} />
            } */}

            {
            value === 1 && <OffersAll  setIsLoading={setIsLoading} />
            }

            {
            value === 2 && <ReportDeals  setIsLoading={setIsLoading} />
            }

        </div>
    )
}

export default Offers