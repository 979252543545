import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import useAuthRequest from '../../../services/requests.service.js';
import { Segmented, Tabs } from 'antd';
import InformationView from './InformationView.js';
import AccountsSub from './AccountsSub.js';

const items = [
  {
    key: 1,
    label: 'Vue générale',
  },
  {
    key: 2,
    label: 'Mes comptes',
  },

];

const SettingsSub = ({ page, setPage }) => {


  const { id } = useParams();
  const request = useAuthRequest();
  const [value, setValue] = useState(1);
  const [subscriptions, setSubscriptions] = useState([]);
  const [invoices, setInvoices] = useState([])
  const [invoice, setInvoice] = useState(null)
  const [stripeProducts, setStripeProducts] = useState(null);

  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    if(page){

      getStripeInvoices()
      getStripeNextInvoice();
      getStripeSubscriptions();
      getStripeProducts();
    }
  }, [])
  




  const onChange = (key) => {
    setValue(key)
  };

  const getStripeProducts= async () => {
    let res = await request.getDataAPI('/stripe/products')
    setStripeProducts(res?.products)
  }

  const getStripeSubscriptions = async () => {
    setIsLoading(true)

    let res = await request.getDataAPI('/stripe/subscriptions/' + page.customerId);
    setSubscriptions(res?.subscriptions)
    setIsLoading(false)

  }

  const getStripeInvoices = async () => {
    setIsLoading(true)

    let res = await request.getDataAPI('/stripe/invoices/' + page.customerId);
    setInvoices(res?.invoices)
    setIsLoading(false)

  }

  const getStripeNextInvoice= async () => {
    setIsLoading(true)

    try{
      let res = await request.getDataAPI('/stripe/upcominginvoice/' + page.customerId);
      setInvoice(res?.upcomingInvoice)

    }catch(e){
      setInvoice(null)

    }
    setIsLoading(false)

  }

  return (
    <div className='h-full m-4 md:w-full '>
      <Tabs defaultActiveKey={1} type="card" items={items} onChange={onChange} />
      {
        value === 1 && <InformationView  isLoading={isLoading} page={page} subscriptions={subscriptions} invoice={invoice} invoices={invoices} stripeProducts={stripeProducts}/>
      }
      {
        value === 2 && <AccountsSub page={page}/>
      }
    </div>
  )
}

export default SettingsSub