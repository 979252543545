import { useAuth } from "../hooks/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../images/logos/logo-violet.png';
import AuthImage from '../images/auth-image.jpg';
import { Button, Form, Input } from "antd";

const Login = () => {
    const [form] = Form.useForm();
    const auth = useAuth();
    const navigate = useNavigate();
    const handleSubmitEvent = (values) => {
        console.log(values)

        if (values.email && values.password) {
            auth.login(values);
            return;
        }
        alert("Please provide a valid input");
    };

    return (
        <main className="bg-white dark:bg-slate-900">
            <div className="relative md:flex">
                {/* Content */}
                <div className="md:w-1/2">
                    <div className="min-h-[100dvh] h-full flex flex-col justify-center items-center">
                        <img className="absolute top-1 left-1" src={Logo} width={200} alt="logo" onClick={()=>navigate('/')} />

                        <div className="max-w-sm mx-auto w-full px-4 py-8">
                            <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6 text-center">Bienvenue!</h1>
                            {/* Form */}
                            <Form
                                layout={"vertical"}
                                form={form}
                                initialValues={{}}
                                onFinish={handleSubmitEvent}
                                style={{
                                    maxWidth: 600,
                                }}
                            >
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        { required: true, message: 'Entrez votre adresse email!' },
                                        { type: 'email', message: 'L\'adresse email n\'est pas valide!' }
                                    ]}
                                >
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Mot de passe"
                                    name="password"
                                    rules={[{ required: true, message: 'Entrez votre mot de passe!' }]}
                                >
                                    <Input type="password" />
                                </Form.Item>
                                <div className="flex items-baseline justify-between mt-14">
                                <div className="mr-1">
                                    <Link className="text-sm underline hover:no-underline" to="/forgot-password">Mot de passe oublié?</Link>
                                </div>
                                <Form.Item className="text-right ">
                                    <Button type="primary" htmlType="submit">Se Connecter</Button>
                                </Form.Item>
                </div>
                              
                            </Form>
                            {/* Footer */}
                            <div className="pt-5 mt-6 border-t border-slate-200 dark:border-slate-700">
                                <div className="text-sm">
                                    Pas de compte? <Link className="font-medium text-royalblue-500 hover:text-royalblue-600 dark:hover:text-royalblue-400" to="/signup">Créer un compte</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Image */}
                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
                </div>
            </div>
        </main>
    );
};

export default Login;
