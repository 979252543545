import { Tabs } from 'antd';
import React, { useState } from 'react'
import FiltersCategories from '../Offers/FiltersCategories/FiltersCategories';
import FeaturedCompany from './FeaturedCompany';


const FeedManagement = ({setIsLoading}) => {

    const [value, setValue] = useState(1);


    const onChange = (key) => {
        console.log(key);
        setValue(key)
    };


    const items = [
        {
            key: 1,
            label: 'Catégories',
        },
        {
            key: 2,
            label: 'Entreprises'
        },



    ];
    return (
        <div className='h-full m-4 md:w-full '>
            <Tabs defaultActiveKey={1} type="card" items={items} onChange={onChange} />
            {
            value === 1 && <FiltersCategories  setIsLoading={setIsLoading} />
            }

            {
            value === 2 && <FeaturedCompany  setIsLoading={setIsLoading} />
            }


        </div>
    )
}

export default FeedManagement