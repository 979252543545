import { Button, Form, Input, Modal, Radio, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import useAuthRequest from '../../../../services/requests.service';
import { Bounce, toast } from 'react-toastify';


const { TextArea } = Input;

const AddAdminModal = ({page, setPage, isModalOpen, setIsModalOpen, setIsLoading, setResponse }) => {

  const [form] = Form.useForm();
  const request = useAuthRequest();
  const [formData, setFormData] = useState({
   
});

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const afterClose = () => {

    console.log("close");
  };

  const handleSubmitEvent = async (values) => {
    console.log(values)
    setIsLoading(true);
 
    try{
      let res = await request.patchDataAPI('/page/'+page._id+'/addAdmin', { ...values });
      setPage(res.newPage)
      setIsModalOpen(false)
      toast.success(res.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        });
    }catch(e){
      console.log(e)
                  toast.error(e.msg, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
                });
    }
   
    setIsLoading(false);


  };

  return (
    <Modal
      centered
      title="Ajouter administrateur"
     footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      afterClose={afterClose}
      bodyStyle={{ zIndex: 30 }}

    >
      <Form
          layout="vertical"
          form={form}
          initialValues={formData}
          onFinish={handleSubmitEvent}
        
          className='mt-12 '
      >
        
        <Form.Item
            label="Email"
            name='email'
            rules={[{ required: true, message: 'Entrez un email!', }]}


          >
            <Input size="large" />
          </Form.Item>

          <Form.Item
            label="Rôle"
            name='role'
            id='role'
            rules={[{ required: true, message: 'Sélectionnez un rôle!', }]}


          >
            <Radio.Group >
              <Radio value={"admin"}>Administrateur</Radio>
              <Radio value={"editor"}>Editeur</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item className="item-next text-right">
              <Button size="large" shape="round" type="primary" htmlType="submit">
                 Ajouter
              </Button>
          </Form.Item>


      </Form>


    </Modal>
  );
};

export default AddAdminModal;
