import { useAuth } from "../hooks/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../images/logos/logo-violet.png';
import AuthImage from '../images/auth-image.jpg';
import { Button, Form, Input } from "antd";
import { Bounce, toast } from "react-toastify";
import useAuthRequest from "../services/requests.service";

const ResetPassword = () => {
    const [form] = Form.useForm();
    const auth = useAuth();
    const request = useAuthRequest();
    const navigate = useNavigate();

    const handleSubmitEvent = async (values) => {
        console.log(values)
        try {
            // Implement your password change logic here
            console.log(' Change Data:', values);

            // Example: Send request to update password
            let res = await request.postDataAPI('sendResetPassword',{email: values.email});

       
            toast.success(res.msg, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            });
        } catch (e) {
            toast.error(e.msg, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                transition: Bounce,
            }); 
            console.error('Activation error:', e);
        }
    };

    return (
        <main className="bg-white dark:bg-slate-900">
            <div className="relative md:flex">
                {/* Content */}
                <div className="md:w-1/2">
                    <div className="min-h-[100dvh] h-full flex flex-col justify-center items-center">
                        <img className="absolute top-1 left-1" src={Logo} width={200} alt="logo" onClick={()=>navigate('/')}/>

                        <div className="max-w-sm mx-auto w-full px-4 py-8">
                            <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6 text-center">Réinitialisation mot de passe?</h1>
                            {/* Form */}
                            <Form
                                layout={"vertical"}
                                form={form}
                                initialValues={{}}
                                onFinish={handleSubmitEvent}
                                style={{
                                    maxWidth: 600,
                                }}
                            >
                    <Form.Item
                                            label="Mot de passe"
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez votre mot de passe!',
                                                },
                                            ]}
                                            hasFeedback
                                        >
                                            <Input.Password size='large' />
                                        </Form.Item>

                                        <Form.Item
                                            label="Mot de passe de confirmation"
                                            name="cf_password"
                                            dependencies={['password']}
                                            hasFeedback
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Entrez le mot de passe de confirmation!',
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Les mots de passe ne correspondent pas!'));
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password size="large" />
                                        </Form.Item>
                               
                                <Form.Item className="text-right ">
                                    <Button type="primary" htmlType="submit" size="large">Valider</Button>
                                </Form.Item>
                              
                            </Form>
                          
                        </div>
                    </div>
                </div>

                {/* Image */}
                <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
                    <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
                </div>
            </div>
        </main>
    );
};

export default ResetPassword;
