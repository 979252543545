import { Button, Form, Input, Modal, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import { Bounce, toast } from 'react-toastify';
import useAuthRequest from '../../../services/requests.service';

const { Option } = Select;

const CreateFeaturedCompanyModal = ({ isModalOpen, setIsModalOpen, setIsLoading, setFeaturedPages, featuredPages }) => {
  const [step, setStep] = useState(1);
  const request = useAuthRequest();
  const [form] = Form.useForm();

  const [formData, setFormData] = useState({});
  const [companyOptions, setCompanyOptions] = useState([]); // Holds search results
  const [isSearching, setIsSearching] = useState(false); // To indicate searching

  useEffect(() => {
    form.setFieldsValue(formData);
  }, [formData, form]);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const afterClose = () => {
    setStep(1);
    form.resetFields();
    setFormData({});
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormData((prevData) => ({
      ...prevData,
      ...allValues,
    }));
  };

  // Function to handle company search
  const handleCompanySearch = async (value) => {
    if (value.length < 3) return; // Don't search for very short inputs

    setIsSearching(true);
    try {
      const res = await request.getDataAPI(`/pages/search?name=${value}`);
      const companyResults = res.pages || [];
      setCompanyOptions(companyResults);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setCompanyOptions([]);
    } finally {
      setIsSearching(false);
    }
  };

  const onFinish = async () => {
    console.log('Final Form Data:', formData);

    setIsLoading(true);
    try {
      let res = await request.postDataAPI('featuredPage', { ...formData });
      setFeaturedPages([...featuredPages, res.newPage]);
      setIsLoading(false);

      toast.success(res.msg, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      setIsModalOpen(false);
    } catch (error) {
      setIsLoading(false);

      toast.error(error.msg ? error.msg : 'Une erreur est survenue', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      setIsModalOpen(false);
    }
  };

  return (
    <Modal
      centered
      bodyStyle={{ height: '65vh', maxHeight: '65vh', overflowY: 'hidden' }}
      footer={null}
      open={isModalOpen}
      onCancel={handleCancel}
      afterClose={afterClose}
    >
      <div className="flex flex-col w-full h-full items-center">
        <div className="flex flex-col">
          <h2 className="font-semibold text-2xl text-center">Créer une nouvelle catégorie</h2>
        </div>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          onValuesChange={onValuesChange}
          className="mt-10"
          style={{ width: '100%', overflowY: 'auto' }}
        >
          <Form.Item
            label="Nom de l'entreprise"
            name="page"
            rules={[
              {
                required: true,
                message: 'Entrez le nom de l\'entreprise!',
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Rechercher une entreprise"
              onSearch={handleCompanySearch}
              filterOption={false}
              notFoundContent={isSearching ? 'Recherche en cours...' : 'Aucune entreprise trouvée'}
              onChange={(value) => {
                const selectedCompany = companyOptions.find((company) => company.name === value);
                if (selectedCompany) {
                  setFormData((prevData) => ({
                    ...prevData,
                    company: selectedCompany.name,
                    // Add other fields if needed
                  }));
                }
              }}
            >
              {companyOptions.map((company) => (
                <Option key={company._id} value={company._id}>
                  {company.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Position"
            name="rank"
          >
            <Input size="large" type="number" />
          </Form.Item>

          <Form.Item className="flex justify-end mt-10">
            <Button size="large" type="primary" htmlType="submit" className="ml-2">
              Créer
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateFeaturedCompanyModal;
