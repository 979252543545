import { Button, Space, Table, Tag, Modal } from 'antd';
import React from 'react';
import { Bounce, toast } from 'react-toastify';
import moment from 'moment';
import useAuthRequest from '../../../../services/requests.service';
import {EyeOutlined, DeleteOutlined} from '@ant-design/icons';

const { confirm } = Modal;

const ReportDealsTable = ({ datas, setDeals, setIsModalOpen, setCurrentDeal,  currentPage, pageSize, total, onPageChange, setPage }) => {
  const request =useAuthRequest()
  const CustomHeader = (props) => {
    return (
      <th {...props} className="bg-blue-500 text-white p-2 text-left">
        {props.children}
      </th>
    );
  };

  const handleUpdate = async (record) => {
      setIsModalOpen(true);

      let newRecordDeal = {...record};
      newRecordDeal.isRead = true;

      await request.putDataAPI('/reportDeal/'+record._id, newRecordDeal)

      setCurrentDeal(record);
  }

  const handleDelete = (record) => {
    confirm({
      title: 'Êtes-vous sûr de vouloir supprimer ce signalement?',
      content: 'Cette action est irréversible',
      okText: 'Oui',
      okType: 'danger',
      cancelText: 'Non',
      centered: true,
      async onOk() {

        try{
        
           let res = await request.deleteDataAPI(`/reportDeal/${record._id}`)
            // Filter out the deal that matches the record
            const updatedDealsArray = datas.reportDeals.filter((deal) => deal._id !== record._id);
            
            // Update the deals state with the new deals array and updated count
            setDeals({
              ...datas,
              reportDeals: updatedDealsArray,
              count: updatedDealsArray.length,
            });

            toast.success(res.msg, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
              });

        }catch(e){
          toast.error('Une erreur est survenue', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            });
        }

      },
    });
  };

  const columns = [
    {
      title: 'Offres',
      dataIndex: 'deal',
      key: 'deal',
      render: (text) => <span>{text.offer}</span>,
    },

    {
      title: 'Messages',
      dataIndex: 'message',
      key: 'message',
      render: (text) => <span>{text}</span>,
    },


    {
      title: 'Date Publication',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '20%',
      responsive: ['md'], // Hidden on small screens (xs, sm)

      render: (createdAt) => (
        <span>{moment(createdAt).format('DD/MM/YYYY')}</span>
      ),
    },

    {
      title: 'Actions',
      key: 'action',
      width: '10%',
      render: (_, record) => (
        <Space size="small">
          <Button onClick={() => handleUpdate(record)}><EyeOutlined /></Button>
          <Button danger onClick={() => handleDelete(record)}><DeleteOutlined/></Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={datas.reportDeals}
      className="bg-white dark:bg-slate-800 shadow rounded-lg"
      rowClassName={(record) => (record.isRead === false ? 'unread-row' : '')} // Apply class based on isRead value
      components={{
        header: {
          cell: CustomHeader,
        },
      }}
      pagination={{
        current: currentPage,
        pageSize: pageSize,
        total: total,
        onChange: onPageChange,
      }}
    />
  );
  
};

export default ReportDealsTable;
